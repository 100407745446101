<div class="formgrid grid">
  <!-- <p class="col-12 text-secondary mb-2">Filter by...</p> -->

  <div class="col-4 p-fluid">
    <!-- <label class='p-input-icon-right'>
      <input
        type='text'
        pInputText
        [(ngModel)]='filterService.searchText'
        (change)='emit()'
      />
      <i class='pi pi-search'></i>
    </label> -->
    <span class="p-float-label p-input-icon-right search-filter">
      <input id="searchText" type="text" pInputText [(ngModel)]="filterService.searchText" (change)="emit()" />
      <i class="pi pi-search"></i>
      <label htmlFor="searchText">Filter by name or email</label>
    </span>
  </div>

  <div class="col-4 p-fluid">
    <span class="p-float-label p-input-icon-right search-filter">
      <p-dropdown
        id="accessLevel"
        placeholder="Access level"
        [options]="accessLevelOptions"
        [(ngModel)]="filterService.accessLevel"
        (onChange)="emit()"
        [showClear]="true"
      ></p-dropdown>
      <label htmlFor="accessLevel">Access level</label>
    </span>
  </div>

  <div class="col p-fluid">
    <span class="p-float-label p-input-icon-right search-filter">
      <p-dropdown
        id="roles"
        placeholder="Roles"
        [options]="roleOptions"
        optionLabel="name"
        optionValue="value"
        [(ngModel)]="filterService.role"
        (onChange)="emit()"
        [showClear]="true"
      ></p-dropdown
      ><label htmlFor="roles">Roles</label>
    </span>
  </div>
</div>
