import { Injectable } from '@angular/core';
import { UserAdminPageAccountDisplayData } from './user-admin-page.component';
import { escapeRegExp } from 'lodash-es';
import { Role } from '../../../-services-/auth-framework/api-constants/roles/role';

@Injectable()
export class UapFilterService {
  constructor() {}

  public searchText = '';
  public accessLevel: string | null = null;
  public role: Role | null = null;

  /**
   * Decides whether to display an account in the page or not
   * @param account evaluation subject
   */
  evaluate(account: UserAdminPageAccountDisplayData): boolean {
    return this.accountFitsSearchText(account) && this.accountFitsAccessLevel(account) && this.accountFitsRole(account);
  }

  private accountFitsSearchText(account: UserAdminPageAccountDisplayData): boolean {
    const searchText = this.searchText;
    if (searchText.length === 0) {
      return true;
    }

    // generate a regexp to search without regard to cases
    const searchPattern = new RegExp(escapeRegExp(searchText), 'gi');
    return searchPattern.test(account.username) || searchPattern.test(account.email);
  }

  private accountFitsAccessLevel(account: UserAdminPageAccountDisplayData): boolean {
    const accessLevel = this.accessLevel;
    if (accessLevel) {
      if (accessLevel === 'All users') {
        return true;
      } else if (accessLevel === 'Has access') {
        return !account.isBlocked && !account.isDisabled;
      } else if (accessLevel === 'Has no access') {
        return account.isBlocked || account.isDisabled;
      }
    }
    return true;
  }

  private accountFitsRole(account: UserAdminPageAccountDisplayData): boolean {
    if (!this.role) {
      return true;
    }

    const selectedRoleDisplayName = this.role.displayName;
    return account.role.includes(selectedRoleDisplayName);
  }
}
