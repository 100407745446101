// Simple hook type

import { Path } from '../../path.model';
import { BCDisplayTransformer } from '../transformer-resolver';

export abstract class TransformerResolveHook {

  abstract run(path: Path): BCDisplayTransformer|null;

}
