<p-card header='Reset Lost Password' class='reset-password-page-card block'>

  <p-header>
    <img alt='estm logo' src='../../../assets/logos/estm_logo.png' />
  </p-header>

  <div class="p-fluid inner-box">

    <span class="p-float-label">
      <input type="password" pInputText [(ngModel)]="newPassword" />
      <label>New Password</label>
    </span>

    <span class="p-float-label">
      <input type="password" pInputText [(ngModel)]="confirmPassword" />
      <label>Confirm Password</label>
    </span>

    <p-button label="Confirm" styleClass="mt-2 p-button-danger" (click)="confirm()"></p-button>

  </div>

</p-card>
