<div class="p-fluid" *ngIf="!isForgotPassMFAshown">
  <span class="p-float-label">
    <input
      type="password"
      pInputText
      [(ngModel)]="password"
      [ngClass]="{ 'p-invalid': invalid }"
      (keyup.enter)="onPasswordConfirmDebounced()"
      appAutoFocus
      autocomplete="current-password"
    />
    <label>Password</label>
  </span>
  <small class="p-invalid p-error" *ngIf="invalid">{{ errorMessage }}</small>
</div>

<div class="p-fluid">
  <app-suspense-button
    *ngIf="!isForgotPassMFAshown"
    [loading]="loading"
    label="Confirm"
    (buttonClick)="onPasswordConfirmDebounced()"
  ></app-suspense-button>
  <ng-container *ngIf="isForgotPassMFAshown">
    <p class="note-text">If you have not enabled MFA yet, click on "MFA not enabled" to request a password reset.</p>
  </ng-container>
  <p-button label="Forgot your password?" (onClick)="openMFABox()" styleClass="p-button-secondary p-button-outlined mt-3"></p-button>
</div>

<div class="p-fluid" *ngIf="isForgotPassMFAshown">
  <form [formGroup]="formGroup" (ngSubmit)="onForgotPassword(true)">
    <span class="p-float-label mt-3">
      <input formControlName="mfaControl" pInputText />
      <label>Enter MFA Code</label>
    </span>
    <div class="error-message" *ngIf="formGroup.get('mfaControl')!.invalid && formGroup.get('mfaControl')!.touched">
      <div *ngIf="formGroup.get('mfaControl')!.errors?.required">MFA is required.</div>
      <div *ngIf="formGroup.get('mfaControl')!.errors?.pattern">MFA must contain exactly 6 digits</div>
    </div>

    <div class="p-fluid" *ngIf="isForgotPassMFAshown">
      <p-button
        label="Request password reset"
        type="submit"
        [disabled]="!formGroup.valid || forgotPasswordButtonDisabled"
        styleClass="p-button-primary mt-3"
      ></p-button>
    </div>
  </form>
  <div class="p-fluid" *ngIf="isForgotPassMFAshown">
    <p-button label="MFA not enabled" styleClass="p-button-primary mt-3" (onClick)="onForgotPassword(false)"></p-button>
  </div>
</div>
