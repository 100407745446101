import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface FeedbackAddParams {
  userId: string;
  content: string;
  title: string;
}

@Injectable()
export class FeedbackService {
  constructor(private http: HttpClient) {}

  addFeedback = ({ userId, content, title }: FeedbackAddParams) => {
    return this.http.post('api/userFeedback/add', {
      title,
      content,
      userId
    });
  };
}
