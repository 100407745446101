import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventData, EVENT_TYPE, EVENT_STATUS } from './about.types';
import { Observable } from 'rxjs';

@Injectable()
export class AboutService {
  constructor(private http: HttpClient) {}

  getAboutInformation = (eventType: EVENT_TYPE): Observable<EventData[]> => {
    return this.http.post<EventData[]>(`api/system-information/all`, {
      eventType,
      asc: false
    });
  };
}
