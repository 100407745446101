import { PanelMenuModule } from 'primeng/panelmenu';
import { CardModule } from 'primeng/card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { TabMenuModule } from 'primeng/tabmenu';
import { FieldsetModule } from 'primeng/fieldset';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { TreeModule } from 'primeng/tree';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';

@NgModule({
    exports: [
        CommonModule,
        MenubarModule,
        InputTextModule,
        ButtonModule,
        SharedModule,
        FileUploadModule,
        TabMenuModule,
        FieldsetModule,
        TabViewModule,
        PanelModule,
        CardModule,
        TabViewModule,
        PanelMenuModule,
        MenuModule,
        SidebarModule,
        TreeModule,
        ProgressSpinnerModule,
        ToastModule,
        MessagesModule,
        MessageModule
    ],
    imports: []
})
export class PrimeNgModule {}
