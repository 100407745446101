import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class LoginPageService {

  state: LPCState = 'ROOT';

  // internal specs
  username!: string;
  password!: string;
  mfaToken!: string;
  mfaMessage!: string;

  // an event that any child component can fire
  loginSuccess = new EventEmitter<void>();

  acceptToS = false;

}

export type LPCState = 'ROOT' | 'PASSWORD' | 'MFA' | 'CHANGE_PASSWORD';
