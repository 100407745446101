<p>

  <p-inputMask
    mask="999999"
    placeholder="123456"
    [(ngModel)]="value"
    (keyup.enter)="confirm()"
  ></p-inputMask>

  <p-button
    [disabled]="isLoading"
    styleClass="ml-2"
    (click)="confirm()"
  >{{isLoading ? 'Loading...' : 'Confirm'}}</p-button>

</p>

<p *ngIf="error.length > 0" class="p-error">
  {{error}}
</p>
