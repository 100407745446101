<div>

  <p *ngIf="error" class="p-error">
    Error: {{error.message}}
  </p>

  <div *ngIf="!error">
    <p *ngIf="isLoading">Loading...</p>
    <app-enable-mfa-modal-content
      *ngIf="!isLoading"
      [data]="enableMFAResponse"
    ></app-enable-mfa-modal-content>
  </div>

</div>
