import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { eSupportRole } from '../../../-services-/auth-framework/api-constants/roles/role-instances';
import { SwalSuccess } from '../../../common/util/swal-mixins/swal-success';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-create-e-support',
  templateUrl: './create-e-support.component.html',
  styleUrls: ['./create-e-support.component.css']
})
export class CreateESupportComponent implements OnInit {
  formGroup = new FormGroup({
    userName: new FormControl('', [Validators.required, forbiddenCharactersValidator()])
  });
  private addEsupportUrl = 'api/users/add-esupport';
  isLoading = false;
  showDomainSuffix: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) {}

  ngOnInit(): void {}

  applyColorContrast(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const enteredValue = inputElement.value;

    this.showDomainSuffix = !!enteredValue;
  }

  confirm() {
    this.isLoading = true;
    let data = this.formGroup.value;

    this.http.post<any>(`${this.addEsupportUrl}`, data).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.formGroup.reset();
        this.messageService.add({
          severity: 'success',
          summary: 'User Created',
          detail: `Esupport user created successfully.`
        });
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error?.error?.message || 'An error occurred while creating the esupport user.'
        });
      }
    });
  }
}

export function forbiddenCharactersValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = /[@|]/.test(control.value);
    return forbidden ? { forbiddenCharacters: { value: control.value } } : null;
  };
}
