// This class loads the Logo for the header
// It should be instantiated by the host, passing in a reference to the authService,
// and handle all subscription to log in / log out events.
// It should upload host's logoSrc directly.

import type { AuthProviderService } from '../../../-services-/auth-framework/auth-provider.service';
import { HeaderComponent } from '../header.component';
import { Subscription } from 'rxjs';
import { LegacyDirectApiRequest } from '../../../-services-/auth-framework/api-connect/legacy-direct-api-request';

const DEFAULT_SRC = '/assets/logos/estm_logo.png';

export class LogoSrcLoader {

  subscriptions!: Subscription[];

  constructor(private host: HeaderComponent, private authService: AuthProviderService) {
  }

  setUp() {

    this.host.logoSrc = DEFAULT_SRC;

    // set up subscriptions
    this.subscriptions = [
      this.authService.logInEvent.subscribe(this.onLogIn.bind(this)),
      this.authService.logOutEvent.subscribe(this.onLogOut.bind(this))
    ];

    // if logged in, trigger first refresh
    // (it'll probably have happened before the subscription has been set up)
    if (this.authService.isLoggedIn) this.onLogIn();

  }

  tearDown() {

    // unsubscribe all
    this.subscriptions.forEach(s => s.unsubscribe());

  }

  private doSetLogo(src: string): void {
    this.host.logoSrc = src;
  }

  private revertToDefault(): void {
    this.doSetLogo(DEFAULT_SRC);
  }

  async onLogIn(): Promise<void> {

    let apiLogo = await loadRawSrc();

    if (apiLogo) {
      this.doSetLogo(apiLogo);
    } else {
      this.revertToDefault();
    }

  }

  async onLogOut(): Promise<void> {
    this.revertToDefault();
  }

}

/**
 * Private function to launch a request to the API
 * Might return null if the API has nothing for us.
 */
async function loadRawSrc(): Promise<string|null> {

  try {

    const response = await LegacyDirectApiRequest.get('/api/logo/byMasterAccountId');

    if (response.logoPath && response.logoPath.length > 0) {
      return response.logoPath;
    } else {
      return null;
    }

  } catch (e) {
    return null;
  }

}
