<div class="m-3 p-3 grid p-card">
  <div class="grid p-fluid center-container">
    <div class="col-12">
      <h1 class="m-0">Create a Site Admin</h1>
    </div>

    <div class="col-12 flex gap-2" style="align-items: baseline">
      <div class="mr-3">Account Type:</div>
      <p-radioButton name="companyType" label="Company" value="Organization" [(ngModel)]="accountType" class="mt-1 block"></p-radioButton>
      <p-radioButton
        name="companyType"
        label="Individual"
        value="Individual"
        [(ngModel)]="accountType"
        (onClick)="companyName = ''"
        class="mt-1 block"
      ></p-radioButton>
    </div>

    <div class="col-12">
      <span class="p-float-label">
        <input pInputText [(ngModel)]="companyName" />
        <label> <span *ngIf="!isIndividual" style="color: red; font-size: larger">*</span> Name </label>
      </span>
    </div>

    <div class="col-12">
      <span class="p-float-label">
        <input pInputText [(ngModel)]="companyCode" maxlength="25" />
        <label> <span style="color: red; font-size: larger">*</span> Code </label>
      </span>
    </div>

    <div class="col-12">
      <span class="p-float-label">
        <input pInputText [(ngModel)]="email" />
        <label> <span style="color: red; font-size: larger">*</span> Email </label>
      </span>
    </div>

    <div class="col-6">
      <h4 class="m-2">Roles</h4>
      <p-checkbox [readonly]="true" label="SiteAdmin" [binary]="true" [(ngModel)]="siteAdminRole" styleClass="ml-3"></p-checkbox>
    </div>

    <div class="col-6">
      <h4 class="m-2">Applications</h4>
      <p-checkbox label="Tax Manager" [binary]="true" [(ngModel)]="taxManagerEnabled" styleClass="ml-3"></p-checkbox>
      <p-checkbox label="CA Viewer" [binary]="true" [(ngModel)]="caViewerEnabled" styleClass="ml-3" [readonly]="true"></p-checkbox>
    </div>

    <div class="col-6 mt-3">
      <span class="p-float-label">
        <p-inputNumber [(ngModel)]="numAllowedUsers" [showButtons]="true" [min]="1"></p-inputNumber>
        <label>Number of allowed active users:</label>
      </span>
    </div>
    <div class="col-6 mt-3">
      <span class="p-float-label">
        <p-inputNumber [(ngModel)]="numAllowedConcurrentUsers" [showButtons]="true" [min]="1"></p-inputNumber>
        <label>Number of allowed concurrent users:</label>
      </span>
    </div>
    <div class="col-6 mt-3">
      <span class="p-float-label">
        <p-inputNumber [(ngModel)]="maxPortfolios" [showButtons]="true" [min]="0"></p-inputNumber>
        <label>Maximum portfolios:</label>
      </span>
    </div>

    <div class="col-12">
      <p-button
        label="Confirm"
        [icon]="isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        [disabled]="isLoading || !isValid"
        (click)="handleConfirm()"
      ></p-button>
    </div>
  </div>
</div>
