<div class="thirteen">
  <h1>Max allowed concurrent users: {{ maxConcurrentUsers }}</h1>
</div>
<div class="col-12">
  <p-messages *ngIf="isConcurrentUsersExceeded" [value]="exceededUsersMessage" [enableService]="false" [closable]="false"></p-messages>
  <p-messages *ngIf="isConcurrentUsersEqual" [value]="equalUsersMessage" [enableService]="false" [closable]="false"></p-messages>
</div>

<div [style]="{ 'text-align': 'end', display: 'flex', 'flex-direction': 'row-reverse', gap: '1rem' }">
  <button [disabled]="!selectedItems.length" label="Force logoff" pButton type="button" (click)="forceLogOff()" icon="pi pi-user"></button>
</div>

<p-table
  [value]="data"
  dataKey="id"
  [scrollable]="true"
  styleClass="mt-3 p-datatable-sm p-datatable-gridlines"
  responsiveLayout="scroll"
  [resizableColumns]="true"
  [(selection)]="selectedItems"
>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn style="width: 4rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th pResizableColumn class="font-bold">Email</th>
      <th pResizableColumn class="font-bold">Roles</th>
      <th pResizableColumn class="font-bold" pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
      <th pResizableColumn class="font-bold">Online Sessions</th>
      <th pResizableColumn class="font-bold">Last Login</th>
      <th pResizableColumn class="font-bold">Last Refresh</th>
      <th pResizableColumn style="width: 4rem" alignFrozen="right" pFrozenColumn [frozen]="true"></th>
    </tr>
    <tr *ngIf="isLoading">
      <td colspan="8">
        <p-progressBar *ngIf="isLoading" mode="indeterminate"></p-progressBar>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr [pContextMenuRow]="item" class="stapled-excp-row">
      <td>
        <p-tableCheckbox [value]="item"></p-tableCheckbox>
      </td>
      <td>{{ item.email }}</td>
      <td>{{ item.roles.join(', ') }}</td>
      <td><p-tag [value]="item.status.toUpperCase()" [severity]="getSeverity(item.status)"></p-tag></td>

      <td>{{ item.onlineSessions }}</td>
      <td><span class="p-column-title">Last Login</span> {{ item.lastLoginTime | date: 'EEE, d MMM y, h:mm:ss a' }}</td>
      <td><span class="p-column-title">Last Refresh</span> {{ item.lastRefreshTime | date: 'EEE, d MMM y, h:mm:ss a' }}</td>

      <td alignFrozen="right" pFrozenColumn [frozen]="true">
        <table-actions [item]="item" [getTableActions]="getMenuOptions" (onSelect)="onSelectSessionUser($event)"></table-actions>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="10" style="text-align: center" class="text-secondary">No users found</td>
    </tr>
  </ng-template>
</p-table>
