<div class="p-card p-2 m-5 primary-container">

  <h1>Privacy Policy</h1>

  <p>
    We respect our customer’s privacy and any data we receive or collect from or about them or their business is used
    for purely internal purposes and not passed to any third parties except if required by law.
  </p>

  <h3>Information we collect or have access to</h3>

  <p>
    We collect or have access to a lot of different information about our customers as a result their contacts with us
    and data they may send us. CGTView is a web based database which contains information about customers’ staff that
    use the system including staff member names and email addresses and the frequency with which members access CGTView.
  </p>

  <h3>Use of Information</h3>

  <p>
    We use the information we collect from CGTView to analyse usage of CGTView to help in making improvements to the
    system. From time to time we also use this information to advise customers’ staff of enhancements, new information
    and other changes to CGTView and to seek their feedback on their usage of CGTView.
  </p>

</div>
