import { Component, DestroyRef, OnInit, OnDestroy } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { NavigationEnd, Router } from '@angular/router';
import { delay, filter } from 'rxjs/operators';
import { AuthProviderService } from './-services-/auth-framework/auth-provider.service';
import { loginExemptPageList } from './login-exempt-page-list';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { extractErrorText } from './utilities/extract-error-text';
import { globalError$ } from './error-handling/uncaught-error-reflector/error.observable';
import { ErrorEvent } from './error-handling/uncaught-error-reflector/error-event';
import { CompactModeService } from './compact-mode.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})
export class AppComponent implements OnInit, OnDestroy {
  compactMode: boolean = false;
  private compactModeSubscription!: Subscription;

  constructor(
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private authProviderService: AuthProviderService,
    private msgService: MessageService,
    private destroyRef: DestroyRef,
    private compactModeService: CompactModeService
  ) {}

  title = 'eSTM';
  // isLoggedIn: boolean = false;

  ngOnInit(): void {
    this.compactModeSubscription = this.compactModeService.getCompactMode().subscribe((compactMode) => {
      this.compactMode = compactMode;
    });
    this.setPrimeNGConfigs();
    this.setUpRouteChangeReaction();
    this.setUpErrorReaction();
  }

  ngOnDestroy() {
    this.compactModeSubscription.unsubscribe();
  }

  private setPrimeNGConfigs() {
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation({
      dateFormat: 'dd/mm/yy'
    });
  }

  private setUpRouteChangeReaction() {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.onRouteChange());
  }

  /**
   * Listens to router navigation event,
   * and redirect to log in page if the user isn't logged in
   */
  onRouteChange(): void {
    const currentRoute = this.router.url.split('?')[0]; // ignore everything after ?

    if (!loginExemptPageList.includes(currentRoute) && !this.authProviderService.isLoggedIn) {
      // this.isLoggedIn = false;
      this.router.navigate(['/logIn']);
    } else {
      // this.isLoggedIn = currentRoute !== '/logIn';
      if (this.authProviderService.isMFAEnforcedButNotEnabled()) {
        const userId = this.authProviderService.account!?.id;
        if (currentRoute !== `/users/profile/${userId}`) {
          this.router.navigate(['users', 'profile', userId], { state: { showMfaEnforcedInfo: true } });
        }
      }
    }
  }

  private setUpErrorReaction() {
    globalError$.pipe(takeUntilDestroyed(this.destroyRef), delay(10)).subscribe((error) => this.onGlobalErrorCaught(error));
  }

  private onGlobalErrorCaught(event: ErrorEvent) {
    if (event.isSilenced()) return;
    console.error(event.originalError);
    // this.msgService.add({
    //   severity: 'error',
    //   summary: 'Error',
    //   detail: `An unexpected error occurred. Error description: "${extractErrorText(event.originalError)}". Try refreshing the page or try again later. If the problem persists, please contact the administrator.`,
    //   life: 10000
    // });
  }
}
