<div class="grid">
  <div class="col-6">
    <h3>Step I.</h3>

    <p>Scan this code with Google Authenticator or another compatible app:</p>

    <qr-code [errorCorrectionLevel]="'M'" [value]="data.secret.otpauth_url"></qr-code>

    <p>Unable to scan the code?</p>
    <button pButton type="button" (click)="showSetupCode()">Show Setup key</button>
    <div *ngIf="showCode" class="code-flex">
      <p>{{ data.secret.base32 }}</p>
      <p-button icon="pi pi-copy" (click)="copySetupCode()" [text]="true" [raised]="true" severity="secondary"></p-button>
    </div>
  </div>

  <div class="col-6">
    <h3>Step II.</h3>

    <p>Enter the authentication code shown on your app:</p>

    <app-mfa-confirm-inputgroup></app-mfa-confirm-inputgroup>
  </div>
</div>
