import { Path } from '../../../path.model';
import { BCDisplayTransformer } from '../../transformer-resolver';
import { TransformerResolveHook } from '../hook';
import { AliasTransformer, HideTransformer } from '../../transformers';

/**
 * For a path like "userSecurityAdmin/edit/67", remove "edit" from the path,
 * and rename the ID to "edit".
 * So that the user does not click on "edit" and get a 404.
 */
export class UserSecurityEditBasePathRemoverHook extends TransformerResolveHook {

  run(path: Path): BCDisplayTransformer | null {

    const isUserSecurityEditRoute = path.value[0] === 'userSecurityAdmin' && path.value[1] === 'edit';
    if (!isUserSecurityEditRoute) return null;

    if (path.value.length === 2) {
      return HideTransformer;
    }

    if (path.value.length === 3) {
      return AliasTransformer('edit');
    }

    // Should never get here
    throw new Error('Unexpected path length');

  }

}
