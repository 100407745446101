// This hook removes the search params in cgt view.

import { TransformerResolveHook } from './hook';
import { BCDisplayTransformer } from '../transformer-resolver';
import { Path } from '../../path.model';
import { AliasTransformer } from '../transformers';

export class CGTViewParamsRemoverHook extends TransformerResolveHook {

  run(path: Path): BCDisplayTransformer | null {

    if (/cgtview?.+/.test(path.lastRouteName)) {
      return AliasTransformer('CGT View');
    }

    if (/dividend-calc+/.test(path.lastRouteName)) {
      return AliasTransformer('Dividend Calculator');
    }

    return null;

  }

}
