import { Component, Input, OnInit } from '@angular/core';
import { LegacyDirectApiRequest } from '../../../../-services-/auth-framework/api-connect/legacy-direct-api-request';

@Component({
  selector: 'app-user-last-access-time-loader',
  templateUrl: './user-last-access-time-loader.component.html',
  styleUrls: ['./user-last-access-time-loader.component.css']
})
export class UserLastAccessTimeLoaderComponent implements OnInit {

  @Input() userId!: string|number;

  // internal states
  isLoading = true;
  value = '';
  hasError = false;
  errorText = '';

  constructor() { }

  async loadValue(): Promise<void> {

    // send API request
    const response = await LegacyDirectApiRequest.get(`/api/userLoginActivities/${this.userId}`);

    const date = new Date(response.loginDateTime);
    this.isLoading = false;
    this.value = date.toLocaleDateString();

  }

  ngOnInit(): void {
    this.loadValue().catch(e => {
      this.hasError = true;
      this.errorText = e.toString();
    });
  }

}
