<div class="p-3 formgrid">
  <!-- Top title -->
  <app-uap-header></app-uap-header>

  <!-- 3 Fields -->
  <app-user-admin-page-filters (filterChange)="refreshFilters()"></app-user-admin-page-filters>

  <!-- Data table and associated state-specific components -->
  <!-- <app-notice-card icon="pi pi-spin pi-spinner" content="Loading..." *ngIf="isLoading && !hasError"></app-notice-card> -->
  <!-- <app-notice-card icon="pi pi-exclamation-triangle" [content]="errorText" *ngIf="hasError"></app-notice-card> -->

  <div class="mt-4">
    <p-table
      dataKey="id"
      [value]="displayedAccounts"
      styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      [resizableColumns]="true"
      [loading]="isLoading"
      [paginator]="true"
      [rows]="50"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[25, 50, 100]"
    >
      <!-- (onRowSelect)="goToUserRoles($event)" -->
      <!-- [selectionMode]="hasWriteAccess ? 'single' : undefined" -->
      <ng-template pTemplate="header">
        <tr>
          <th pResizableColumn>Name</th>
          <th pResizableColumn>Email</th>
          <th pResizableColumn>Last Activity</th>
          <th pResizableColumn>MFA Enabled</th>
          <th pResizableColumn>MFA Enforced</th>
          <th pResizableColumn>Enabled</th>
          <th pResizableColumn>System Enabled</th>
          <th pResizableColumn>Role</th>
          <th *ngIf="hasWriteAccess" pResizableColumn style="width: 4rem" alignFrozen="right" pFrozenColumn [frozen]="true"></th>
        </tr>
        <tr *ngIf="isLoading">
          <td colspan="9">
            <p-progressBar *ngIf="isLoading" mode="indeterminate"></p-progressBar>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr (dblclick)="hasWriteAccess ? goToUserRoles(user) : undefined" class="user-row">
          <!-- [pSelectableRow]="user"  -->
          <td>{{ renderUserName(user.username) }}</td>
          <td>{{ user.email }}</td>
          <td><app-fancy-datetime [date]="user.lastLoginTimeISO8601" fallback="Unknown / Never Logged In"></app-fancy-datetime></td>
          <!-- <td>{{ user.isMFAEnabled }}</td> -->
          <td style="width: 5rem; text-align: center">
            <i *ngIf="user.isMFAEnabled" class="pi pi-check-circle" style="font-size: 1.5rem; color: var(--green-400)"></i>
            <i *ngIf="!user.isMFAEnabled" class="pi pi-times-circle" style="font-size: 1.5rem; color: var(--red-400)"></i>
          </td>
          <!-- <td>{{ user.isMFAEnforced }}</td> -->
          <td style="width: 5rem; text-align: center">
            <i *ngIf="user.isMFAEnforced" class="pi pi-check-circle" style="font-size: 1.5rem; color: var(--green-400)"></i>
            <i *ngIf="!user.isMFAEnforced" class="pi pi-times-circle" style="font-size: 1.5rem; color: var(--red-400)"></i>
          </td>
          <!-- <td>{{ user.isDisabled }}</td> -->
          <td style="width: 5rem; text-align: center">
            <i *ngIf="!user.isDisabled" class="pi pi-check-circle" style="font-size: 1.5rem; color: var(--green-400)"></i>
            <i *ngIf="user.isDisabled" class="pi pi-times-circle" style="font-size: 1.5rem; color: var(--red-400)"></i>
          </td>
          <td>
            <i *ngIf="!user.isBlocked" class="pi pi-check-circle" style="font-size: 1.5rem; color: var(--green-400)"></i>
            <i *ngIf="user.isBlocked" class="pi pi-times-circle" style="font-size: 1.5rem; color: var(--red-400)"></i>
          </td>
          <td>{{ user.role }}</td>
          <td *ngIf="hasWriteAccess" alignFrozen="right" pFrozenColumn [frozen]="true">
            <table-actions [item]="user" [getTableActions]="getMenuOptions"></table-actions>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td style="text-align: center" [attr.colspan]="9" class="text-secondary">No users found</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
