import { Injectable, NgModule } from '@angular/core';
import { Route, RouterModule, UrlSegment } from '@angular/router';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { LoginPageComponent } from './account-mgmt/login-page/login-page.component';
import { UserAdminPageContainerComponent } from './account-mgmt/user-admin-page/user-admin-page-container.component';
import { userPagesChildren } from './account-mgmt/user-admin-page/routes';
import { TermsPagesContainerComponent } from './tos-pages/terms-pages-container/terms-pages-container.component';
import { termsPageChildren } from './tos-pages/terms-page-children';
import { ResetForgottenPasswordPageComponent } from './account-mgmt/reset-forgotten-password-page/reset-forgotten-password-page.component';
import { UserManualComponent } from './user-manual/user-manual.component';
import { AuthServiceModule } from './-services-/auth-framework/auth-service.module';
import {
  AdministratorRole,
  AdvisorRole,
  BackofficeAdminRole,
  BackofficeRole,
  CompanyManagerRole,
  SiteAdminRole,
  eSupportRole
} from './-services-/auth-framework/api-constants/roles/role-instances';
import { Role } from './-services-/auth-framework/api-constants/roles/role';
import { BillingService } from './account-mgmt/user-admin-page/billing/billing-page.service';
import { CanMatchRoute, CustomRoute } from './services/route-verification.service';
import { TaxManger } from './-services-/auth-framework/api-constants/applications/application-instances';
import { AboutComponent } from './about/about.component';

const routes: CustomRoute[] = [
  {
    path: 'portfolios/:portfolioId/sub-portfolios',
    loadChildren: () => import('./dashboard/sub-portfolios/sub-portfolios.module').then((m) => m.SubPortfoliosModule),
    canMatch: [CanMatchRoute],
    roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole],
    applications: [TaxManger]
  },
  {
    path: 'portfolios/:portfolioId/user-groups',
    loadChildren: () => import('./user-group-portfolio/user-group-portfolio.module').then((m) => m.UserGroupPortfolioModule),
    canMatch: [CanMatchRoute],
    roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole],
    applications: [TaxManger]
  },
  { path: 'logIn', component: LoginPageComponent },
  { path: 'resetPassword', component: ResetForgottenPasswordPageComponent },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'user-manual',
    // , component: UserManualComponent
    loadChildren: () => import('./user-manual/user-manual.module').then((m) => m.UserManualModule)
    // canMatch: [CanMatchRoute],
    // roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole]
    // applications: [TaxManger]
  },
  {
    path: 'users',
    component: UserAdminPageContainerComponent,
    children: userPagesChildren
    // canMatch: [CanMatchRoute],
    // roles: [eSupportRole, SiteAdminRole, AdministratorRole, CompanyManagerRole]
  },
  {
    path: 'user-groups',
    loadChildren: () => import('./user-group-management/user-group-management.module').then((m) => m.UserGroupMgmtModule),
    canMatch: [CanMatchRoute],
    roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole],
    applications: [TaxManger]
  },
  {
    path: 'clients',
    loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
    canMatch: [CanMatchRoute],
    roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole],
    applications: [TaxManger]
  },
  {
    path: 'terms',
    component: TermsPagesContainerComponent,
    children: termsPageChildren
  },
  {
    path: 'activities',
    loadChildren: () => import('./activities/activities.module').then((m) => m.ActivitiesModule),
    canMatch: [CanMatchRoute],
    roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole],
    applications: [TaxManger]
  },
  {
    path: 'activities-no-clients',
    loadChildren: () => import('./activities-no-clients-page/activities-no-clients-page.module').then((m) => m.ActivitiesNoClientsPageModule),
    canMatch: [CanMatchRoute],
    roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole],
    applications: [TaxManger]
  },
  {
    path: 'external-data',
    loadChildren: () => import('./external-data/external-data.module').then((m) => m.ExternalDataModule),
    canMatch: [CanMatchRoute],
    roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole],
    applications: [TaxManger]
  },
  {
    path: 'user-security-management',
    loadChildren: () => import('./user-security-mgmt/user-security-mgmt.module').then((m) => m.UserSecurityMgmtModule),
    canMatch: [CanMatchRoute],
    roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole],
    applications: [TaxManger]
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
    canMatch: [CanMatchRoute],
    roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole],
    applications: [TaxManger]
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [AuthServiceModule, RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  providers: [BillingService],
  exports: [RouterModule]
})
export class AppRoutingModule {}
