import { LegacyDirectApiRequest } from '../../../-services-/auth-framework/api-connect/legacy-direct-api-request';

export interface ProfileDataSet {
  firstName: string;
  lastName: string;
  jobTitle: string;
  department: string;
  location: string;
  isMFAEnabled: boolean;
}

export class UserProfilePageApiInterface {

  constructor(private userId: string) {
  }

  async getData(): Promise<ProfileDataSet> {

    const data = await LegacyDirectApiRequest.get(`/api/users/byId/${this.userId}`);

    const { firstName, lastName, jobTitle, department, location, isMFAEnabled } = data;
    return { firstName, lastName, jobTitle, department, location, isMFAEnabled };

  }

  async setData(data: ProfileDataSet): Promise<void> {
    await LegacyDirectApiRequest.put('/api/users/updateUser', data);
  }

  async setPassword(password: string): Promise<void> {
    await LegacyDirectApiRequest.patch('/api/users/updatePassword', {
      userPassword: password
    });
  }

}
