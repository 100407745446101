import { Component, OnInit } from '@angular/core';
import { MfaService } from '../../mfa.service';
import { EnableMfaResponse } from '../../../../../../../api-connector/api-collections/mfa/enable-mfa-response.model';

@Component({
  selector: 'app-enable-mfa-modal-loader',
  templateUrl: './enable-mfa-modal-loader.component.html',
  styleUrls: ['./enable-mfa-modal-loader.component.css']
})
export class EnableMfaModalLoaderComponent implements OnInit {

  isLoading = true;
  error: any;
  enableMFAResponse!: EnableMfaResponse;

  constructor(private mfaService: MfaService) { }

  ngOnInit(): void {
    this.load()
      .catch(e => this.error = e)
      .finally(() => this.isLoading = false);
  }

  private async load() {
    this.enableMFAResponse = await this.mfaService.requestEnableMFA();
  }

}
