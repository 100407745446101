import { Injectable } from '@angular/core';
import { ApiHttpRequest } from '../../../../../api-connector/api-http-request';
import { swalConfirm } from '../../../../common/util/swal-mixins/native-fn-replacements';
import { AuthProviderService } from '../../../../-services-/auth-framework/auth-provider.service';

@Injectable()
export class MfaService {

  constructor(private authService: AuthProviderService) {
  }

  async disableMFA() {
    try {

      if (!await swalConfirm('Are you sure you want to disable MFA?')) return;
      await ApiHttpRequest.disableMFA();

      alert('MFA has been disabled. Please log in again.');
      await this.onMFAStatusChange();

    } catch (e) {
      alert(e);
    }
  }

  /**
   * Sends a request to enable MFA to the back-end
   * and get data to render in the modal
   */
  async requestEnableMFA() {
    return await ApiHttpRequest.enableMFA();
  }

  async confirmEnableMFA(mfaToken: string) {
    await ApiHttpRequest.verifyMFAToken(mfaToken);
    alert('MFA has been successfully enabled. Please log in again using your new authenticator.');
    await this.onMFAStatusChange();
  }

  /**
   * This will be called whenever MFA has been successfully enabled or disabled
   * @private
   */
  private async onMFAStatusChange() {
    await this.authService.logOut(true);
  }

}
