// @ts-nocheck

import { Component, OnInit } from '@angular/core';
import { AuthProviderService } from '../../../../-services-/auth-framework/auth-provider.service';
import { LoginPageService } from '../../login-page.service';
import { debounce } from 'lodash-es';
import { InvalidCredentialsError, MFARequiredError, PWChangeRequiredError } from '../../../../-services-/auth-framework/auth-errors';
import { MessageService } from 'primeng/api';
import { extractErrorText } from '../../../../utilities/extract-error-text';
import { ApiHttpRequest } from '../../../../../api-connector/api-http-request';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.css']
})
export class PasswordFormComponent implements OnInit {
  loading = false;
  invalid = false;
  isForgotPassMFAshown = false;
  errorMessage: string;
  mfaerrorMessage: string;
  forgotPasswordButtonDisabled = false;

  password: string;

  formGroup = new FormGroup({
    mfaControl: new FormControl('', [Validators.required, Validators.pattern(/^\d{6}$/)])
  });

  constructor(private authProvider: AuthProviderService, private loginPageService: LoginPageService, private messageService: MessageService) {}

  ngOnInit(): void {}

  onPasswordConfirmDebounced: Function = debounce(() => this.onPasswordConfirm(), 100);

  async onPasswordConfirm(): Promise<void> {
    try {
      if (this.password!.length > 50) {
        throw new Error('Entered password must be less than or equal to 50 characters.');
      }
      // normal track
      this.loading = true;
      this.loginPageService.password = this.password;

      await this.authProvider.logIn(this.loginPageService.username, this.loginPageService.password, this.loginPageService.acceptToS);

      // Good!
      this.loginPageService.loginSuccess.emit();
    } catch (e: any) {
      this.handleError(e);
    }
  }

  private handleError(e: Error) {
    // is it a known type?
    if (e instanceof MFARequiredError) {
      // just transition to MFA slide
      this.loginPageService.state = 'MFA';
      this.loginPageService.mfaMessage = e.message;
    } else if (e instanceof PWChangeRequiredError) {
      // transition to password change slide
      this.loginPageService.state = 'CHANGE_PASSWORD';
    } else if (e.message === 'Please try again.') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Your authentication information is incorrect. Please try again.'
      });
      this.loginPageService.state = 'ROOT';
    }
    // no, not a known type

    this.loading = false;
    this.invalid = true;

    if (e instanceof InvalidCredentialsError) {
      this.errorMessage = `That doesn't look right. ${extractErrorText(e)}`;
    } else if (e instanceof PWChangeRequiredError) {
      this.errorMessage = `Please change password before proceeding.`;
    } else if (e instanceof MFARequiredError) {
      this.errorMessage = `Please enter TOTP before proceeding.`;
    } else {
      this.errorMessage = `Sorry, an error occurred. ${extractErrorText(e)}`;
      throw e;
    }
  }

  openMFABox() {
    this.isForgotPassMFAshown = !this.isForgotPassMFAshown;
  }

  onForgotPassword = async (isMfaEnabled: boolean): Promise<void> => {
    try {
      this.loading = true;
      const mfaPasscode = isMfaEnabled ? this.formGroup.value.mfaControl : null;
      await ApiHttpRequest.requestForgotPasswordEmail(this.loginPageService.username, mfaPasscode);
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'An email has been sent with instructions to reset your password.'
      });
      this.forgotPasswordButtonDisabled = true;
    } catch (e: any) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: e.message || 'Your authentication information is incorrect. Please try again.'
      });
    } finally {
      this.loading = false;
    }
  };
}
