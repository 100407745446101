import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-suspense-button',
  templateUrl: './suspense-button.component.html',
  styleUrls: ['./suspense-button.component.css']
})
export class SuspenseButtonComponent implements OnInit {

  @Input() loading = false;
  @Input() label!: string;
  @Input() loadingLabel = 'Loading...';
  @Input() disabled = false;

  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onLogIn(): void {
    this.buttonClick.emit();
  }
}
