// Provides children for app-routing.module.ts related to user pages.

import { UserAdminPageComponent } from './user-admin-page/user-admin-page.component';
import { UserRolesPageComponent } from './roles/user-roles-page.component';
import { UserProfilePageComponent } from './profile/user-profile-page.component';
import { CreateUserPageComponent } from './new/create-user-page.component';
import { BillingPageComponent } from './billing/billing-page.component';
import { CreateSiteAdminPageComponent } from './create-site-admin/create-site-admin-page.component';
import { CreateESupportComponent } from './create-e-support/create-e-support.component';
import { UserSessionsComponent } from './user-sessions/user-sessions.component';
import {
  AdministratorRole,
  CompanyManagerRole,
  SiteAdminRole,
  eSupportRole
} from 'src/app/-services-/auth-framework/api-constants/roles/role-instances';
import { CanMatchRoute } from 'src/app/services/route-verification.service';
import { Route } from '@angular/router';

export const userPagesChildren = [
  {
    path: '',
    component: UserAdminPageComponent,
    roles: [eSupportRole, SiteAdminRole, AdministratorRole, CompanyManagerRole],
    canMatch: [CanMatchRoute]
  },
  {
    path: ':userId/user-groups',
    loadChildren: () => import('../../user-group-user/user-group-user.module').then((m) => m.UserGroupUserModule),
    roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole],
    canMatch: [CanMatchRoute]
  } as Route,
  { path: 'roles/:id', component: UserRolesPageComponent, roles: [eSupportRole, SiteAdminRole, AdministratorRole], canMatch: [CanMatchRoute] },
  { path: 'profile/:id', component: UserProfilePageComponent },
  { path: 'billing', component: BillingPageComponent, roles: [SiteAdminRole, AdministratorRole, CompanyManagerRole], canMatch: [CanMatchRoute] },
  { path: 'new', component: CreateUserPageComponent, roles: [SiteAdminRole, AdministratorRole], canMatch: [CanMatchRoute] },
  { path: 'user-sessions', component: UserSessionsComponent, roles: [SiteAdminRole, AdministratorRole], canMatch: [CanMatchRoute] },
  { path: 'createSiteAdmin', component: CreateSiteAdminPageComponent, roles: [eSupportRole], canMatch: [CanMatchRoute] },
  { path: 'createESupport', component: CreateESupportComponent, roles: [eSupportRole], canMatch: [CanMatchRoute] }
];
