import { Component, OnInit } from '@angular/core';
import { SwalError } from '../../../common/util/swal-mixins/swal-error';
import { SwalSuccess } from '../../../common/util/swal-mixins/swal-success';
import { Router } from '@angular/router';
import { SiteAdminRole } from '../../../-services-/auth-framework/api-constants/roles/role-instances';
import { Role } from '../../../-services-/auth-framework/api-constants/roles/role';
import { ApiHttpRequest } from '../../../../api-connector/api-http-request';
import { extractErrorText } from '../../../utilities/extract-error-text';
import { CaViewer, TaxManger } from 'src/app/-services-/auth-framework/api-constants/applications/application-instances';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-create-site-admin-page',
  templateUrl: './create-site-admin-page.component.html',
  styleUrls: ['./create-site-admin-page.component.css']
})
export class CreateSiteAdminPageComponent implements OnInit {
  // input models
  accountType = 'Organization';
  companyName = '';
  companyCode = '';
  email = '';

  // - roles
  siteAdminRole = true;

  // - applications
  taxManagerEnabled = false;
  caViewerEnabled = true;

  numAllowedUsers = 1;
  numAllowedConcurrentUsers = 1;
  maxPortfolios = 0;

  // internal states
  isLoading = false;

  constructor(private router: Router, private msgService: MessageService) {}

  ngOnInit(): void {}

  async handleConfirm(): Promise<void> {
    try {
      this.assertValidity();

      await ApiHttpRequest.createEsupportOrSiteAdminAccount(this.constructApiPostBody());
      this.msgService.add({ severity: 'success', detail: 'The user has been created' });

      await this.router.navigate(['users']);
    } catch (e: unknown) {
      const errorMsg = extractErrorText(e);
      this.msgService.add({ severity: 'error', detail: errorMsg });
    }
  }

  get isValid() {
    if (this.accountType === 'Individual') {
      return !!(this.companyCode && this.email);
    } else {
      return !!(this.companyName && this.companyCode && this.email);
    }
  }

  get isIndividual() {
    return this.accountType === 'Individual';
  }

  //region Private methods...

  private constructApiPostBody() {
    return {
      userEmail: this.email,
      organizationName: this.companyName,
      organizationCode: this.companyCode,
      allowedUsersNumber: this.numAllowedUsers,
      maxConcurrentUsers: this.numAllowedConcurrentUsers,
      maxPortfolios: this.maxPortfolios,
      masterEstmApplicationIds: this.selectedApplicationIds,
      userRoleIds: this.selectedRoles.map((role) => role.apiInteger),
      accountType: this.accountType as any
    };
  }

  /**
   * A list of API-ready IDs of enabled applications
   * @private
   */
  private get selectedApplicationIds(): number[] {
    const result = [];
    if (this.taxManagerEnabled) {
      result.push(TaxManger.id);
    }
    if (this.caViewerEnabled) {
      result.push(CaViewer.id);
    }
    return result;
  }

  /**
   * A list of enabled roles
   * @private
   */
  private get selectedRoles(): Role[] {
    return this.siteAdminRole ? [SiteAdminRole] : [];
  }

  /**
   * Asserts that values selected are valid, or throw an exception.
   */
  private assertValidity(): void {
    // 1. Fields cannot be empty
    if ((this.accountType === 'Organization' && this.companyName === '') || this.email === '') {
      throw new Error('Company name and email cannot be empty');
    }
    if ((this.maxPortfolios < 0)) {
      throw new Error('Max portfolios must not be negative');
    }
  }

  //endregion
}
