import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-enable-mfa-modal',
  templateUrl: './enable-mfa-modal.component.html',
  styleUrls: ['./enable-mfa-modal.component.css']
})
export class EnableMfaModalComponent implements OnInit {

  @Input() visible!: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

}
