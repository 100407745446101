import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompactModeService {
  private compactModeKey = 'compactMode';
  private compactModeSubject: BehaviorSubject<boolean>;

  constructor() {
    const compactModeValue = localStorage.getItem(this.compactModeKey);
    const initialCompactMode = compactModeValue ? JSON.parse(compactModeValue) : false;
    this.compactModeSubject = new BehaviorSubject<boolean>(initialCompactMode);
  }

  getCompactMode(): Observable<boolean> {
    return this.compactModeSubject.asObservable();
  }

  setCompactMode(compactMode: boolean): void {
    this.compactModeSubject.next(compactMode);
    localStorage.setItem(this.compactModeKey, JSON.stringify(compactMode));
  }
}
// ```

// In the updated `CompactModeService`, we introduce a `compactModeSubject` of type `BehaviorSubject<boolean>`. In the constructor, we retrieve the `compactMode` value from `localStorage` and set it as the initial value of the `compactModeSubject`. The `getCompactMode()` method returns the `compactModeSubject` as an observable. The `setCompactMode()` method updates the `compactModeSubject` with the new value and also stores it in `localStorage`.