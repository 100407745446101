import { Component, OnInit } from '@angular/core';
import { LoginPageService } from '../../login-page.service';
import { AuthProviderService } from '../../../../-services-/auth-framework/auth-provider.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-username-form',
  templateUrl: './username-form.component.html',
  styleUrls: ['./username-form.component.css']
})
export class UsernameFormComponent implements OnInit {
  inputId = uuidv4();

  inputText?: string;
  loading = false;
  invalid = false;
  errorMessage = '';

  // Controls whether to prompt the user to accept the terms and conditions
  tosNag = false;

  constructor(public loginPageService: LoginPageService, private authProvider: AuthProviderService) {}

  ngOnInit(): void {}

  onConfirm() {
    try {
      // verify with authProvider
      const username = this.inputText?.trim();
      // success! transition to PASSWORD
      this.loginPageService.username = username!;
      // check is username is valid email

      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailPattern.test(username!)) {
        throw new Error('Entered email is not a valid email');
      }
      if (username!.length > 100) {
        throw new Error('Entered email must be less than or equal to 100 characters.');
      }
      this.loginPageService.state = 'PASSWORD';
    } catch (e: any) {
      this.loading = false;
      this.invalid = true;
      this.errorMessage = e.message;
    }
  }

  tosNagMessage = 'Please review and accept the terms and conditions.';

  maybeNagAboutToS() {
    if (!this.loginPageService.acceptToS && !this.tosNag) {
      this.tosNag = true;
    }
  }
}
