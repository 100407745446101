import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UncaughtErrorReflector } from './uncaught-error-reflector/uncaught-error-reflector';
import { RevolverService } from './revolver.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: UncaughtErrorReflector
    }
  ]
})
export class ErrorHandlingModule {
  constructor(private revolver: RevolverService) {}
}
