import { validate as uuidValidate } from 'uuid';

// This class represents a path, and the calculations thereof.
export class Path {
  constructor(public value: string[]) {}

  /**
   * Parses a url (no hostname and search params) into a Path
   * @param url - the url to parse. e.g. '/users/riley/123'
   */
  static fromURL(url: string): Path {
    const value = url
      .split('?')[0] // This step removes all query params
      .split('/')
      .filter((item) => item.length > 0);
    return new Path(value);
  }

  /**
   * Calculates a sub-path from this path.
   * @param depth - how many levels to include.
   */
  getSubPath(depth: number): Path {
    return new Path(this.value.slice(0, depth));
  }

  /**
   * Gets all possible sub-paths, including this itself.
   */
  getAllSubPaths(): Path[] {
    const result = [];
    for (let i = 1; i < this.value.length; ++i) {
      const currCrumb = this.getSubPath(i);
      const crumbValue = currCrumb.value;
      const lastPath = crumbValue[crumbValue.length - 1];
      // console.log(`${lastPath} -> ${!uuidValidate(lastPath)}`);
      // console.log(`${lastPath} -> ${!isNaN(Number(lastPath))}`);
      const isUuidOrNumber = uuidValidate(lastPath) || !isNaN(Number(lastPath));
      if (!isUuidOrNumber) result.push(currCrumb);
    }
    result.push(this);

    return result;
  }

  get lastRouteName(): string {
    return this.value.slice(-1)[0];
  }

  get fullURL(): string {
    return this.value.join('/');
  }
}
