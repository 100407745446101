<p-card header="Log In" class="login-page-card block">
  <p-header>
    <img alt="estm logo" ngSrc="assets/logos/estm_logo.png" priority width="450" height="259" />
  </p-header>

  <div [ngSwitch]="state">
    <app-username-form *ngSwitchCase="'ROOT'"></app-username-form>

    <app-password-form *ngSwitchCase="'PASSWORD'"></app-password-form>

    <app-mfa-form *ngSwitchCase="'MFA'"></app-mfa-form>

    <app-force-password-change-form *ngSwitchCase="'CHANGE_PASSWORD'"></app-force-password-change-form>
  </div>
</p-card>
