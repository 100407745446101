import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Roles} from '../../../../../-services-/auth-framework/api-constants/roles/roles';
import {UapFilterService} from "../../uap-filter.service";

@Component({
  selector: 'app-user-admin-page-filters',
  templateUrl: './user-admin-page-filters.component.html',
  styleUrls: ['./user-admin-page-filters.component.css']
})
export class UserAdminPageFiltersComponent implements OnInit {

  // IO
  @Output() filterChange = new EventEmitter();

  constructor(public filterService: UapFilterService) {
  }

  ngOnInit(): void {
  }

  emit(): void {
    this.filterChange.emit();
  }

  accessLevelOptions = [
    'All users',
    'Has access',
    'Has no access'
  ];

  roleOptions = [
    ...Roles.list.map(role => ({
      name: role.displayName,
      value: role
    }))
  ];

}
