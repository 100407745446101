import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { AuthProviderService } from '../../../-services-/auth-framework/auth-provider.service';
import { PrimeIcons } from 'primeng/api';
import { Router } from '@angular/router';
import {
  BackofficeAdminRole,
  AdministratorRole,
  CompanyManagerRole,
  eSupportRole,
  SiteAdminRole,
  BackofficeRole
} from '../../../-services-/auth-framework/api-constants/roles/role-instances';
import { HostSelectorInstance } from './host-selector';
import { ProcessingEngineSelectorInstance } from './processing-engine-selector';
import { environment } from 'src/environments/environment';
import { TaxManger } from 'src/app/-services-/auth-framework/api-constants/applications/application-instances';

@Component({
  selector: 'app-cog-button',
  templateUrl: './cog-button.component.html',
  styleUrls: ['./cog-button.component.css']
})
export class CogButtonComponent implements OnInit, OnChanges {
  constructor(private authProviderService: AuthProviderService, private router: Router) {}

  menuModel: MenuItem[] = [];
  showMenu = false;

  get canAccessClients() {
    const hasReqRole = [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole].some((role) =>
      this.authProviderService.verifyUserRole(role)
    );
    const isTaxManager = [TaxManger].some((role) => this.authProviderService.verifyUserApplication(role));
    const isAllowed = isTaxManager && hasReqRole;
    return isAllowed;
  }

  get canAccessUserMgmt() {
    const hasReqRole = [eSupportRole, SiteAdminRole, AdministratorRole, CompanyManagerRole].some((role) =>
      this.authProviderService.verifyUserRole(role)
    );
    return hasReqRole;
  }

  get canAccessUserGroupMgmt() {
    const hasReqRole = [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole].some((role) =>
      this.authProviderService.verifyUserRole(role)
    );
    return hasReqRole;
  }

  generateMenuModel(): MenuItem[] {
    const isDevelopDeployedEnv = environment.envVar.DEPLOYED_ENV_NAME.toLowerCase() === 'develop';

    const wheelMenuItems: MenuItem[] = [
      {
        label: 'Users',
        icon: PrimeIcons.USER,
        routerLink: ['users'],
        visible: this.canAccessUserMgmt
      },
      {
        label: 'User Groups',
        icon: PrimeIcons.USERS,
        routerLink: ['user-groups'],
        visible: this.canAccessUserGroupMgmt
      },
      {
        label: 'Clients',
        icon: PrimeIcons.FILE,
        routerLink: ['clients'],
        visible: this.canAccessClients
      },
      ...(isDevelopDeployedEnv
        ? [
            {
              separator: true
            },
            {
              label: HostSelectorInstance.isUsingLocalhost ? 'Switch to cloud server' : 'Switch to local server',
              icon: 'pi pi-sitemap',
              command: this.changeServer
            }
          ]
        : [])
    ];

    if (isDevelopDeployedEnv) {
      const engineSelectorMenuItem = {
        label: ProcessingEngineSelectorInstance.isUsingLegacyEngine ? 'Switch to Cloud Engine' : 'Switch to COM Engine',
        icon: 'pi pi-sitemap',
        command: this.changeEngine
      };
      wheelMenuItems.push(engineSelectorMenuItem);
    }

    this.showMenu = wheelMenuItems.filter((item) => ('visible' in item ? item.visible : true)).length > 0;

    return wheelMenuItems;
  }

  isLoggedIn(): boolean {
    return this.authProviderService.isLoggedIn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.menuModel = this.generateMenuModel();
  }

  ngOnInit(): void {
    this.menuModel = this.generateMenuModel();
  }

  changeEngine = () => {
    if (ProcessingEngineSelectorInstance.isUsingLegacyEngine) {
      ProcessingEngineSelectorInstance.switchToCloudEngine();
    } else {
      ProcessingEngineSelectorInstance.switchToLegacyEngine();
    }
    this.menuModel = this.generateMenuModel();
  };

  changeServer = () => {
    if (HostSelectorInstance.isUsingLocalhost) {
      HostSelectorInstance.switchToCloudServer();
    } else {
      HostSelectorInstance.switchToLocalhost();
    }
    this.menuModel = this.generateMenuModel();
  };
}
