import {Component, Input, OnInit} from '@angular/core';
import {AuthProviderService} from "../../../../../-services-/auth-framework/auth-provider.service";
import {MfaService} from "../mfa.service";

@Component({
  selector: 'app-mfa-control-panel',
  templateUrl: './mfa-control-panel.component.html',
  styleUrls: ['./mfa-control-panel.component.css']
})
export class MfaControlPanelComponent implements OnInit {

  @Input() isMFAEnabled!: boolean;

  modalOpen = false;

  constructor(public authService: AuthProviderService,
              private mfaService: MfaService) { }

  ngOnInit(): void {
  }

  renderMFAStatus() {
    if (this.isMFAEnabled) {
      return 'Enabled';
    } else {
      return 'Disabled';
    }
  }

  enableMFA() {
    this.modalOpen = true;
  }

  async disableMFA() {
    await this.mfaService.disableMFA();
  }

}
