import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EnableMfaResponse } from '../../../../../../../api-connector/api-collections/mfa/enable-mfa-response.model';

@Component({
  selector: 'app-enable-mfa-modal-content',
  templateUrl: './enable-mfa-modal-content.component.html',
  styleUrls: ['./enable-mfa-modal-content.component.css']
})
export class EnableMfaModalContentComponent implements OnInit {
  @Input() data!: EnableMfaResponse;

  constructor() {}

  ngOnInit(): void {}

  showCode: boolean = false;

  showSetupCode() {
    this.showCode = true;
  }

  copySetupCode() {
    navigator.clipboard.writeText(this.data!.secret.base32);
  }
}
