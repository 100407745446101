import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';

interface ISessionUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  totalSessions: string;
  roles: string[];
  status: string;
  onlineSessions: string;
  lastLoginTime: string;
  lastRefreshTime: string;
}

@Component({
  selector: 'app-user-sessions',
  templateUrl: './user-sessions.component.html',
  styleUrls: ['./user-sessions.component.scss']
})
export class UserSessionsComponent {
  isLoading = false;
  errorMessage = '';

  selectedItems: ISessionUser[] = [];
  maxConcurrentUsers?: number;
  isConcurrentUsersExceeded = false;
  isConcurrentUsersEqual = false;
  exceededUsersMessage = [
    {
      severity: 'error',
      summary: 'Alert',
      detail: 'Your organisation has exceeded the maximum allowed concurrent users. Please disable or force logoff one or more users.'
    }
  ];
  equalUsersMessage = [
    {
      severity: 'warn',
      summary: 'Alert',
      detail: 'Your organisation has reached the max allowed concurrent users. Further user logins will not be allowed.'
    }
  ];

  data!: any[];

  selectedUser: ISessionUser | undefined;

  onSelectSessionUser = (sessionUser: ISessionUser) => {
    this.selectedUser = sessionUser;
  };

  constructor(private messageService: MessageService, private http: HttpClient) {}

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.isLoading = true;
    // Make a GET request to userSessions API endpoint
    this.http.get<{ userSessions: any[]; maxConcurrentUsers: number }>('api/userLoginActivities/user-sessions').subscribe((sessionData) => {
      this.data = sessionData.userSessions;
      this.maxConcurrentUsers = sessionData.maxConcurrentUsers;
      this.isLoading = false;
      const onlineUsersCount = sessionData.userSessions.filter((us) => us.status === 'online').length;
      if (onlineUsersCount > sessionData.maxConcurrentUsers) {
        this.isConcurrentUsersExceeded = true;
      } else if (onlineUsersCount === sessionData.maxConcurrentUsers) {
        this.isConcurrentUsersEqual = true;
      }
    });
  }

  getMenuOptions = () => {
    return [
      {
        label: 'Force LogOff User',
        icon: 'pi pi-pencil',
        command: () => this.forceLogOff()
      }
    ] as MenuItem[];
  };

  getSeverity(status: string) {
    switch (status) {
      case 'online':
        return 'success';
      case 'offline':
        return 'warning';
    }
  }

  forceLogOff() {
    this.isLoading = true;
    const users = this.selectedUser ? [this.selectedUser] : this.selectedItems;
    const userIds = users.map((u) => u.id);
    this.http.post<any>('api/userLoginActivities/force-logoff', { userIds }).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'success', detail: 'Successfully marked selected user(s) for force log off' });
      },
      error: (err) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'error', detail: 'Failed to mark selected user(s) for force logoff' });
      }
    });
  }
}
