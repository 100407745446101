<div class="grid form-container">
  <div class="col-12">
    <span class="p-float-label">
      <input id="fullName" type="text" pInputText [(ngModel)]="fullName" [required]="true" />
      <label for="fullName"><span style="color: red; font-size: larger">*</span> Full Name</label>
    </span>
  </div>

  <div class="col-12">
    <span class="p-float-label">
      <input id="email" type="email" pInputText [(ngModel)]="email" />
      <label for="email">Email</label>
    </span>
  </div>

  <div class="col-6">
    <span class="p-float-label">
      <input id="mobile" type="tel" pInputText [(ngModel)]="mobile" />
      <label for="mobile">Mobile</label>
    </span>
  </div>

  <div class="col-6">
    <span class="p-float-label">
      <input id="jobTitle" type="text" pInputText [(ngModel)]="jobTitle" />
      <label for="jobTitle">Job Title</label>
    </span>
  </div>

  <div class="col-6">
    <span class="p-float-label">
      <input id="department" type="text" pInputText [(ngModel)]="department" />
      <label for="department">Department</label>
    </span>
  </div>

  <div class="col-6">
    <span class="p-float-label">
      <input id="basedIn" type="text" pInputText [(ngModel)]="basedIn" />
      <label for="basedIn">Based In</label>
    </span>
  </div>

  <div class="col-12">
    <div class="flex gap-3">
      <button pButton pRipple label="Reset" class="reset-button p-button-secondary" (click)="resetAll()"></button>
      <button pButton pRipple label="Save" class="save-button" [loading]="isSaving" [disabled]="!canSave" (click)="saveChanges()"></button>
    </div>
  </div>
</div>
