import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PrimeNgModule } from './-services-/prime-ng/prime-ng.module';
import { LoginPageComponent } from './account-mgmt/login-page/login-page.component';
import { UsernameFormComponent } from './account-mgmt/login-page/page-forms/username-form/username-form.component';
import { PasswordFormComponent } from './account-mgmt/login-page/page-forms/password-form/password-form.component';
import { MfaFormComponent } from './account-mgmt/login-page/page-forms/mfa-form/mfa-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuspenseButtonComponent } from './account-mgmt/login-page/page-forms/suspense-button/suspense-button.component';
import { UserAdminPageComponent } from './account-mgmt/user-admin-page/user-admin-page/user-admin-page.component';
import { UserRolesPageComponent } from './account-mgmt/user-admin-page/roles/user-roles-page.component';
import { UserProfilePageComponent } from './account-mgmt/user-admin-page/profile/user-profile-page.component';
import { CreateUserPageComponent } from './account-mgmt/user-admin-page/new/create-user-page.component';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { UserAdminPageContainerComponent } from './account-mgmt/user-admin-page/user-admin-page-container.component';
import { CheckboxModule } from 'primeng/checkbox';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MegaMenuModule } from 'primeng/megamenu';
import { TabMenuModule } from 'primeng/tabmenu';
import { AccordionModule } from 'primeng/accordion';
import { ForcePasswordChangeFormComponent } from './account-mgmt/login-page/page-forms/force-password-change-form/force-password-change-form.component';
import { InputTextModule } from 'primeng/inputtext';
import { BillingPageComponent } from './account-mgmt/user-admin-page/billing/billing-page.component';
import { LogoUploaderComponent } from './account-mgmt/user-admin-page/billing/-components-/logo-uploader/logo-uploader.component';
import { BillingProfileFormComponent } from './account-mgmt/user-admin-page/billing/-components-/billing-profile-form/billing-profile-form.component';
import { CreateSiteAdminPageComponent } from './account-mgmt/user-admin-page/create-site-admin/create-site-admin-page.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { AuthServiceModule } from './-services-/auth-framework/auth-service.module';
import { UserLastAccessTimeLoaderComponent } from './account-mgmt/user-admin-page/-components-/user-last-access-time-loader/user-last-access-time-loader.component';
import { TermsAndConditionsPageComponent } from './tos-pages/terms-and-conditions-page/terms-and-conditions-page.component';
import { PrivacyPolicyPageComponent } from './tos-pages/privacy-policy-page/privacy-policy-page.component';
import { TermsPagesContainerComponent } from './tos-pages/terms-pages-container/terms-pages-container.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { UserAdminPageFiltersComponent } from './account-mgmt/user-admin-page/user-admin-page/-components-/user-admin-page-filters/user-admin-page-filters.component';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { MfaControlPanelComponent } from './account-mgmt/user-admin-page/profile/mfa/mfa-control-panel/mfa-control-panel.component';
import { EnableMfaModalComponent } from './account-mgmt/user-admin-page/profile/mfa/enable-mfa-modal/enable-mfa-modal.component';
import { EnableMfaModalLoaderComponent } from './account-mgmt/user-admin-page/profile/mfa/enable-mfa-modal/enable-mfa-modal-loader/enable-mfa-modal-loader.component';
import { EnableMfaModalContentComponent } from './account-mgmt/user-admin-page/profile/mfa/enable-mfa-modal/enable-mfa-modal-content/enable-mfa-modal-content.component';
import { QrCodeModule } from 'ng-qrcode';
import { MfaConfirmInputgroupComponent } from './account-mgmt/user-admin-page/profile/mfa/enable-mfa-modal/enable-mfa-modal-content/mfa-confirm-inputgroup/mfa-confirm-inputgroup.component';
import { InputMaskModule } from 'primeng/inputmask';
import { CreateESupportComponent } from './account-mgmt/user-admin-page/create-e-support/create-e-support.component';
import { ResetForgottenPasswordPageComponent } from './account-mgmt/reset-forgotten-password-page/reset-forgotten-password-page.component';
import { UapHeaderComponent } from './account-mgmt/user-admin-page/user-admin-page/-components-/uap-header/uap-header.component';
import { DATE_PIPE_DEFAULT_OPTIONS, NgOptimizedImage } from '@angular/common';
import { BlockUIModule } from 'primeng/blockui';

import RelativeTimePlugin from 'dayjs/plugin/relativeTime';
import LocalizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import { APIInterceptor } from './APIInterceptor';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { CommonSharedModule } from './common/shared.module';
import { ToolbarModule } from 'primeng/toolbar';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { ReportsModule } from './reports/reports.module';
import { HeaderModule } from './common/header/header.module';
import { LocalStorageService } from './utilities/local-storage.service';
import { UserSessionsComponent } from './account-mgmt/user-admin-page/user-sessions/user-sessions.component';
import { TagModule } from 'primeng/tag';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { UserManualModule } from './user-manual/user-manual.module';
import { AboutComponent } from './about/about.component';
import { CardModule } from 'primeng/card';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { CgtViewModule } from './cgt-view/cgt-view.module';

dayjs.extend(RelativeTimePlugin);
dayjs.extend(LocalizedFormatPlugin);

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    UsernameFormComponent,
    PasswordFormComponent,
    MfaFormComponent,
    SuspenseButtonComponent,
    UserAdminPageComponent,
    UserRolesPageComponent,
    UserProfilePageComponent,
    CreateUserPageComponent,
    UserAdminPageContainerComponent,
    ForcePasswordChangeFormComponent,
    BillingPageComponent,
    LogoUploaderComponent,
    BillingProfileFormComponent,
    CreateSiteAdminPageComponent,
    UserLastAccessTimeLoaderComponent,
    TermsAndConditionsPageComponent,
    PrivacyPolicyPageComponent,
    TermsPagesContainerComponent,
    UserAdminPageFiltersComponent,
    MfaControlPanelComponent,
    EnableMfaModalComponent,
    EnableMfaModalLoaderComponent,
    EnableMfaModalContentComponent,
    MfaConfirmInputgroupComponent,
    CreateESupportComponent,
    ResetForgottenPasswordPageComponent,
    UapHeaderComponent,
    UserSessionsComponent,
    AboutComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PrimeNgModule,
    InputTextModule,
    FormsModule,
    DropdownModule,
    TableModule,
    TagModule,
    MessagesModule,
    CheckboxModule,
    BreadcrumbModule,
    SplitButtonModule,
    MegaMenuModule,
    TabMenuModule,
    AccordionModule,
    InputNumberModule,
    AuthServiceModule,
    RadioButtonModule,
    DialogModule,
    ReactiveFormsModule,
    TooltipModule,
    QrCodeModule,
    InputMaskModule,
    LoadingBarRouterModule,
    CommonSharedModule,
    ToolbarModule,
    ErrorHandlingModule,
    ReportsModule,
    UserManualModule,
    HeaderModule,
    MessageModule,
    NgOptimizedImage,
    BlockUIModule,
    CardModule,
    InputGroupModule,
    InputGroupAddonModule,
    CgtViewModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'en-AU' }, //replace "de-at" with your locale
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'shortDate' } },
    { provide: LocalStorageService, useClass: LocalStorageService }, // providedIn: 'root', can also be used],
    MessageService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
