// This hook transforms certain lastRouteName paths based on a constant map.

import { TransformerResolveHook } from './hook';
import { Path } from '../../path.model';
import { BCDisplayTransformer } from '../transformer-resolver';
import { AliasTransformer } from '../transformers';

export class SpellingSubstitutionHook extends TransformerResolveHook {
  run(path: Path): BCDisplayTransformer | null {
    const lastRouteName = path.lastRouteName?.toLowerCase();
    if (map.hasOwnProperty(lastRouteName)) {
      return AliasTransformer(map[lastRouteName]);
    }

    return null;
  }
}

/**
 * NOTE: Key must be in lower case!
 */
const map: Record<string, string> = {
  cgtview: 'CGT View',
  'cpi-data': 'CPI Data',
  login: 'Log In',
  'user-transactions': 'User Transactions',
  'user-transactions-import': 'Import User Transactions',
  usersecurityadmin: 'User Securities'
};
