import { ErrorHandler } from '@angular/core';
import { globalError$ } from './error.observable';
import { ErrorEvent } from './error-event';

export class UncaughtErrorReflector implements ErrorHandler {

  handleError(error: unknown) {
    globalError$.next(new ErrorEvent(error));
  }

}
