import { Component, Input, OnInit } from '@angular/core';
import { AuthProviderService } from 'src/app/-services-/auth-framework/auth-provider.service';
import {
  AdministratorRole,
  CompanyManagerRole,
  eSupportRole,
  SiteAdminRole
} from '../../../../../-services-/auth-framework/api-constants/roles/role-instances';

@Component({
  selector: 'app-uap-header',
  templateUrl: './uap-header.component.html',
  styleUrls: ['./uap-header.component.css']
})
export class UapHeaderComponent implements OnInit {
  // don't forget:
  // src/app/-components-/header/cog-button/cog-button.component.ts:19
  authorisedRolesByAction = {
    createSiteAdmin: [eSupportRole],
    createUser: [SiteAdminRole, AdministratorRole],
    manageUserSessions: [SiteAdminRole, AdministratorRole],
    manageBilling: [SiteAdminRole, AdministratorRole, CompanyManagerRole]
  };
  companyName!: string;

  constructor(private authService: AuthProviderService) {}

  ngOnInit(): void {
    this.companyName = this.authService.account!?.masterAccountName;
  }

  shouldDisplay(): boolean {
    return this.currentAccountIsESupport();
  }

  private currentAccountIsESupport() {
    if (!this.authService.account) return false;
    return this.authService.account.roles.map((r) => r.roleName).includes(eSupportRole.roleName);
  }

  private currentAccountIsESTMEmail() {
    if (!this.authService.account) return false;
    // NOTE - .au is optional for now. Should fix later
    return /@estm.com(.au)?$/i.test(this.authService.account.email);
  }
}
