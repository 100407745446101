import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthProviderService } from '../../-services-/auth-framework/auth-provider.service';
import { ApiHttpRequest } from '../../../api-connector/api-http-request';

@Component({
  selector: 'app-reset-forgotten-password-page',
  templateUrl: './reset-forgotten-password-page.component.html',
  styleUrls: ['./reset-forgotten-password-page.component.css']
})
export class ResetForgottenPasswordPageComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthProviderService,
    private router: Router
  ) {}

  // ngModels
  newPassword = '';
  confirmPassword = '';

  // states
  isLoading = false;

  ngOnInit(): void {}

  async confirm() {
    try {
      this.isLoading = true;
      await this.doResetPassword();

      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Your password has been reset.'
      });
      await this.router.navigate(['/']);
    } catch (e: any) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        life: 10000,
        detail: e.message || 'Your authentication information is incorrect. Please try again.'
      });
    } finally {
      this.isLoading = false;
    }
  }

  private async doResetPassword() {
    const token = this.activatedRoute.snapshot.queryParams['token'];
    if (typeof token != 'string') throw new Error('Cannot extract token. Please double check if your URL is correct.');

    if (this.newPassword !== this.confirmPassword) throw new Error('Your passwords do not match.');
    //if (!this.authService.passwordMeetsRequirements(this.newPassword)) throw new Error('Your password does not meet the requirements');

    // all clear.
    await ApiHttpRequest.resetForgottenPassword(token, this.newPassword);
  }
}
