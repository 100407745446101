<div class="p-fluid">
  <span class="p-float-label">
    <input
      type="text"
      data-testid="loginInput"
      pInputText
      [(ngModel)]="inputText"
      (keyup.enter)="loginPageService.acceptToS ? onConfirm() : maybeNagAboutToS()"
      appAutoFocus
      autocomplete="username"
    />
    <label>Email</label>
  </span>
  <small class="p-invalid p-error" *ngIf="invalid || tosNag">{{ tosNag ? tosNagMessage : errorMessage }}</small>
</div>

<div class="p-fluid mt-3">
  <p-checkbox [inputId]="inputId" [binary]="true" [(ngModel)]="loginPageService.acceptToS" (onChange)="tosNag = false" />
  <label [for]="inputId">
    I agree to eSTM's
    <a href="https://estm-public-resources.s3.ap-southeast-2.amazonaws.com/eSTM+SaaS+Agreement+-+Online.pdf" target="_blank">Terms and Conditions</a>.
  </label>
</div>

<div (click)="maybeNagAboutToS()">
  <app-suspense-button
    label="Log In"
    [loading]="loading"
    (buttonClick)="onConfirm()"
    [disabled]="!loginPageService.acceptToS || !inputText?.trim()"
  ></app-suspense-button>
</div>
