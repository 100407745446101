<h4 class='p-fluid'>
  For your security, please change your password now.
</h4>

<div class='p-fluid mt-3'>
  <span class="p-float-label">
    <input type="password" appAutoFocus pInputText [(ngModel)]="currentPassword" />
    <label>Current Password</label>
  </span>
</div>

<div class='p-fluid mt-3'>
  <span class="p-float-label">
    <input type="password" pInputText [(ngModel)]="newPassword" />
    <label>New Password</label>
  </span>
</div>

<div class='p-fluid mt-3'>
  <span class="p-float-label">
    <input type="password" pInputText [(ngModel)]="confirmNewPassword" (keyup.enter)="confirm()"/>
    <label>Confirm New Password</label>
  </span>
</div>

<div class='p-fluid mt-3'>
  <p-button
    label='Confirm'
    [disabled]='isLoading'
    icon='{{ isLoading ? "pi pi-spin pi-spinner" : "" }}'
    (click)='confirm()'
  ></p-button>
</div>
