// Maps specific paths to transformers.
import { Path } from '../path.model';
import { MenuItem } from 'primeng/api/menuitem';
import { NullTransformer } from './transformers';
import { TransformerResolveHook } from './resolver-hooks/hook';
import { HideUserIdHook } from './resolver-hooks/hide-user-id.hook';
import { SpellingSubstitutionHook } from './resolver-hooks/spelling-substitution.hook';
import { CGTViewParamsRemoverHook } from './resolver-hooks/cgt-view-params-remover.hook';
import {
  UserSecurityEditBasePathRemoverHook
} from './resolver-hooks/user-security/user-security-edit-base-path-remover.hook';

export type BCDisplayTransformer = (path: Path) => MenuItem | null;

export class TransformerResolver {

  static hooks: TransformerResolveHook[] = [
    new HideUserIdHook(),
    new SpellingSubstitutionHook(),
    new CGTViewParamsRemoverHook(),
    new UserSecurityEditBasePathRemoverHook()
  ];

  static resolve(path: Path): BCDisplayTransformer {

    let answer: BCDisplayTransformer = NullTransformer;

    // run hooks sequentially. If one returns a value, overwrite answer.
    for (const hook of this.hooks) {
      const a = hook.run(path);
      if (a) {
        answer = a;
      }
    }

    return answer;

  }

}
