/**
 * This is the root component for the log in page.
 * See the document below for an explanation on different states:
 * https://docs.google.com/document/d/1_FMSfDp6-9oR6S7_0CoTvHruYpk5m912MOVJOtLJRtI/edit?usp=sharing
 */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UsernameFormComponent } from './page-forms/username-form/username-form.component';
import { PasswordFormComponent } from './page-forms/password-form/password-form.component';
import { MfaFormComponent } from './page-forms/mfa-form/mfa-form.component';
import { AuthProviderService } from '../../-services-/auth-framework/auth-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginPageService, LPCState } from './login-page.service';
import { Actions, Getters } from 'src/app/store';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  providers: [LoginPageService]
})
export class LoginPageComponent implements OnInit, OnDestroy {
  // Form components
  @ViewChild(UsernameFormComponent) usernameForm!: UsernameFormComponent;
  @ViewChild(PasswordFormComponent) passwordForm!: PasswordFormComponent;
  @ViewChild(MfaFormComponent) mfaForm!: MfaFormComponent;

  get state(): LPCState {
    return this.loginPageService.state;
  }

  set state(newState) {
    this.loginPageService.state = newState;
  }

  constructor(
    private authProvider: AuthProviderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public readonly gettersService: Getters,
    public readonly actionsService: Actions,
    private loginPageService: LoginPageService
  ) {}

  private subscriptions: Subscription[] = [];
  ngOnInit(): void {
    this.subscriptions.push(this.loginPageService.loginSuccess.subscribe(this.onLoginSuccess));
    this.authProvider.isAuthenticated().subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        // user is already loggedin
        this.onLoginSuccess();
      }
    });

    // In case app is logged in other tab, just navigate to landing
    if (this.authProvider.isLoggedIn) {
      const store = this.gettersService.store;
      this.router.navigateByUrl(store.lastWorkingPath || '/');
      // this.router.navigate(['/']);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   * What to do if the log in is successful
   */
  onLoginSuccess = (): void => {
    // if the current route is not /login then do nothing {
    const currentRouterUrl = this.router.url;

    // console.log(this.router.url, 'router url');

    if (!currentRouterUrl.startsWith('/logIn')) {
      return;
    }

    const returnTo = this.activatedRoute.snapshot.queryParams.returnTo;
    // 1st check the current route
    console.log('returnTo', returnTo);

    if (returnTo) {
      this.router.navigateByUrl(returnTo);
    } else {
      const store = this.gettersService.store;
      this.router.navigateByUrl(store.lastWorkingPath || '/');
    }
  };
}
