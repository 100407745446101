<div class='p-fluid'>
  <div>{{loginPageService.mfaMessage}}</div>
  <span class="p-float-label mt-3">
    <input
      type='text'
      pInputText
      [(ngModel)]='inputValue'
      [ngClass]='{"p-invalid": invalid}'
      (keyup.enter)='onConfirm()'
      autofocus
    />
    <label>MFA Token</label>
  </span>
  <small class='p-invalid' *ngIf='invalid'>{{ errorMessage }}</small>
</div>

<div class='p-fluid mt-3'>
  <app-suspense-button label='Confirm' [loading]='loading' (buttonClick)='onConfirm()'></app-suspense-button>
</div>
