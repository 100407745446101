import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { PrimeIcons } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-button-loggedout',
  templateUrl: './profile-button-loggedout.component.html',
  styleUrls: ['./profile-button-loggedout.component.css']
})
export class ProfileButtonLoggedoutComponent implements OnInit {

  constructor(private router: Router) { }

  // State
  modalOpen = false;

  menuItems: MenuItem[] = [
    { label: 'You\'re logged out.', disabled: true },
    { label: 'Give Feedback', icon: PrimeIcons.SEND, command: () => this.openFeedbackModal() },
    { label: 'Log In', icon: PrimeIcons.SIGN_IN, command: (e) => this.logIn() }
  ];

  ngOnInit(): void {
  }


  openFeedbackModal() {
    this.modalOpen = true;
  }

  async logIn() {
      await this.router.navigate(['/logIn']);
  }

}
