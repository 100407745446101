import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthProviderService } from '../../../../-services-/auth-framework/auth-provider.service';
import { MessageService } from 'primeng/api';
import { FeedbackService } from './feedback-modal.service';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.css'],
  providers: [FeedbackService]
})
export class FeedbackModalComponent implements OnInit {
  @Input() visible!: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();

  // states
  isLoading = false;

  form = new FormGroup({
    title: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required)
  });

  constructor(private authService: AuthProviderService, private msgService: MessageService, private feedbackService: FeedbackService) {}

  ngOnInit(): void {}

  async submit() {
    try {
      this.isLoading = true;

      if (this.form.valid) {
        const payload = {
          title: this.form.value.title || '',
          content: this.form.value.content || '',
          userId: this.authService.account?.id || ''
        };

        this.feedbackService.addFeedback(payload).subscribe({
          next: (res) => {
            this.msgService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Thank you. Your feedback has been received.'
            });
            this.form.reset();
            this.visibleChange.emit(false);
            this.isLoading = false;
          },
          error: (err) => {
            this.msgService.add({
              severity: 'error',
              summary: 'Error',
              detail: err.message ?? JSON.stringify(err)
            });
            this.visibleChange.emit(false);
            this.isLoading = false;
            throw err;
          }
        });
      }
    } catch (e: any) {
      throw e;
    }
  }
}
