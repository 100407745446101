<div class="p-card grid m-2 p-5 primary-container">

  <h1>Terms and Conditions</h1>

  <p>Please read carefully the following Terms and Conditions of use for CGT View.</p>

	<h3>Acknowledgement and acceptance of General Conditions</h3>
	<p>
		This site is owned by eSTM Pty Ltd (ABN&nbsp;18&nbsp;634&nbsp;813&nbsp;004). Your access to, use of, linking to the Site, use of software or downloading software on the Site (collectively, your “Access”) is conditional upon your acceptance and compliance with the terms, conditions, notices and disclaimers contained on this page and elsewhere on the Site ("General Conditions"). Your Access to the Site constitutes your agreement to be bound by the General Conditions. eSTM reserves the right to amend the General Conditions at any time and you should periodically refer to them on this page and elsewhere on the Site.
		</p>
	<h3>
		Ownership of content on the site
	</h3>
	<p>
		The materials displayed on the Site, including without limitation all information, text, materials, graphics, software, tools, results derived from the use of software and tools, names, logos and trade marks on the Site ("Content") are protected by copyright, trade mark and other intellectual property laws unless expressly indicated otherwise on the Site.
	</p><p>
	You must not modify, copy, reproduce, republish, frame, download onto a computer, upload to a third party, post, transmit or distribute this Content in any way except as expressly provided for in these General Conditions or with the express prior written consent of eSTM.
</p><p>
	Subscribers to eSTM systems and services shall not access this site and use such tools and content on the site other than for their own internal requirements.
</p><p>
	You must not use any spider, screen scraper, robot, or other automated similar software or device ("Prohibited Device") to use or access the Site in any way whatsoever, nor can you use any Prohibited Device (or any similar process) to copy, download or monitor the Content, without eSTM’s prior written approval.
</p>
		<h3>
			Access to the Site
		</h3>
	<p>
	</p><p>
	You may view the Site using your web browser and save an electronic copy, or print out a copy, of parts of this web site solely for your internal use, information, research or study, but only if you keep all Content intact and in the same form as presented on the Site.
</p><p>
	You must not use the Site or the Content in any manner or for any purpose which is unlawful or in any manner which violates any right of eSTM or which is prohibited by the General Conditions.
</p><p>
	You must not use the Content for commercial purposes without first obtaining the express written authority of eSTM. Use of the Content for a commercial purpose is any use other than accessing and using the content for your own internal requirements.
</p>
		<h3>
			Disclaimer and limitation of liability
		</h3>
	<p>
		The Content on the Site is derived from sources believed to be accurate and current. However, neither eSTM nor eSTM directors, officers, agents, employees or contractors (“eSTM Personnel”):
	</p><p style="text-indent:each-line">
	(a) give any representation or warranty as to the reliability, accuracy or completeness of the Content; or
</p><p style="text-indent:each-line">
	(b) warrant that any of the functions contained in any Content or that your Access will be uninterrupted or error-free.
</p><p>
	Neither eSTM nor eSTM Personnel shall be liable in any way for any loss or damage, howsoever arising (whether in negligence or otherwise) out of or in connection with your Access, the Content and/or any omissions from the Content, except where liability is made non-excludable by legislation.
</p><p>
	In the case of goods or services supplied or offered by eSTM or eSTM Personnel, liability for breach of any implied warranty or condition which cannot be excluded is limited at eSTMs option to either:
</p><p style="text-indent:each-line">
	(a) the supply of the goods (or equivalent goods) or services again; or
</p><p style="text-indent:each-line">
	(b) the payment of the cost of having the goods (or equivalent goods) or services supplied again.
</p>
		<h3>
			Indemnity
		</h3>
	<p>
		By accessing this Site and accepting these terms you thereby indemnify and hold harmless eSTM and eSTM Personnel and keep them indemnified, against all loss, actions, proceedings, costs, expenses (including legal fees), claims and damages arising from:
	</p><p style="text-indent:each-line">
	(a) any breach by you of the General Conditions; or
</p><p style="text-indent:each-line">
	(b) reliance by you on any information obtained through the Site; or
</p><p style="text-indent:each-line">
	(c) your Access to the Site; or
</p><p style="text-indent:each-line">
	(d) reliance upon or use of any Content by any person accessing any Content through your web site.
</p>
	<h3>
		Not investment or taxation advice
	</h3>
	<p>
		The Content on the Site provides general information only. It is not intended as investment or taxation advice and must not be relied upon as such.
	</p><p>
		Neither eSTM nor eSTM Personnel give any representation or warranty as to the reliability, accuracy or completeness of any Third Party Material on the Site, Third Party Websites, Linked Sites, or Subsequent Sites, nor do they accept any responsibility arising in any way (including negligence) for errors in, or omissions from such Third Party Material, Third Party Websites, Linked Sites or Subsequent Sites.
	</p>

</div>
