import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthProviderService } from '../../../-services-/auth-framework/auth-provider.service';
import { ProfileDataSet, UserProfilePageApiInterface } from './loader';
import { SwalError } from '../../../common/util/swal-mixins/swal-error';
import { SwalToastSuccess } from '../../../common/util/swal-mixins/swal-toast-success';
import { Router } from '@angular/router';
import { MfaService } from './mfa/mfa.service';

@Component({
  selector: 'app-user-profile-page',
  templateUrl: './user-profile-page.component.html',
  styleUrls: ['./user-profile-page.component.css'],
  providers: [MfaService]
})
export class UserProfilePageComponent implements OnInit {
  constructor(private authProvider: AuthProviderService, private router: Router, ngLocation: Location) {}

  get isMfaMandatory() {
    return this.authProvider.isLoggedIn && this.authProvider.isMFAEnforcedButNotEnabled();
  }

  private apiInterface = new UserProfilePageApiInterface(this.authProvider.account!?.id);

  // states
  isLoading = true;
  isChangingPassword = false;

  // input models
  firstName!: string;
  lastName!: string;
  jobTitle!: string;
  department!: string;
  location!: string;
  isMFAEnabled!: boolean;
  mfaEnforcedInfo = [
    {
      severity: 'info',
      summary: 'Info',
      detail: `Site Admin has enforced MFA on your account. You must enable MFA to proceed further.`
    }
  ];

  email = 'not supported';
  mobile = 'not supported';

  oldPassword = '';
  newPassword = '';
  confirmNewPassword = '';

  ngOnInit(): void {
    this._quokkaDoPromise(this.load());
  }

  onConfirmUserDetails(): void {
    const { firstName, lastName, department, jobTitle, location, isMFAEnabled } = this;
    const data: ProfileDataSet = {
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      department: department?.trim(),
      jobTitle: jobTitle?.trim(),
      location: location?.trim(),
      isMFAEnabled
    };

    this._quokkaDoPromise(
      this.apiInterface.setData(data).then(() => {
        SwalToastSuccess.fire('Your details have been saved');
        this.forceReloadComponent();
      })
    );
  }

  onConfirmPassword(): void {
    // // 2 guards
    // if (!this.authProvider.passwordMeetsRequirements(this.newPassword)) {
    //   SwalError.fire({ text: 'Your password does not meet the requirements' });
    //   return;
    // }

    if (this.newPassword !== this.confirmNewPassword) {
      SwalError.fire({ text: "Your passwords don't match" });
      return;
    }

    this.isChangingPassword = true;

    // do it
    this._quokkaDoPromise(
      this.authProvider
        .changePassword(this.oldPassword, this.newPassword)
        .then(async () => {
          this.isChangingPassword = false;
          alert('Your password has been changed. Please log in again using your new password.');
          await this.authProvider.logOut(true);
        })
        .finally(() => {
          this.isChangingPassword = false;
        })
    );
  }

  private async load(): Promise<void> {
    const data = await this.apiInterface.getData();

    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.department = data.department;
    this.jobTitle = data.jobTitle;
    this.location = data.location;
    this.isMFAEnabled = data.isMFAEnabled;

    this.authProvider.updateAccountDetails(data);

    this.isLoading = false;
  }

  private _quokkaDoPromise(promise: Promise<any>): void {
    promise.catch((e) => {
      SwalError.fire({
        html: '<pre>' + (e?.message || e)?.toString() || 'Something went wrong' + '</pre>'
      });
    });
  }

  private forceReloadComponent() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['users', 'profile', this.authProvider.account!?.id]);
    });
  }
}
