import { Component, OnInit } from '@angular/core';
import { AuthProviderService } from '../../../-services-/auth-framework/auth-provider.service';

@Component({
  selector: 'app-profile-button-container',
  templateUrl: './profile-button-container.component.html',
  styleUrls: ['./profile-button-container.component.css']
})
export class ProfileButtonContainerComponent implements OnInit {

  constructor(public authService: AuthProviderService) { }

  ngOnInit(): void {
  }

}
