<app-notice-card *ngIf="isLoading" icon="pi pi-spin pi-spinner" content="Loading..."></app-notice-card>

<div class="p-card grid m-3 p-3" *ngIf="!isLoading">
  <div class="col-12">
    <h1 class="m-0">Profile</h1>
    <div>Manage your profile and personal information</div>
    <p-messages *ngIf="isMfaMandatory" [value]="mfaEnforcedInfo" [enableService]="false" [closable]="false"></p-messages>
  </div>

  <div *ngIf="!isMfaMandatory" class="col-6 grid p-fluid">
    <h3 class="col-12 m-0">About you</h3>

    <div class="col-12">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="firstName" />
        <label>First name</label>
      </span>
    </div>

    <div class="col-12">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="lastName" />
        <label>Last name</label>
      </span>
    </div>

    <div class="col-12">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="jobTitle" />
        <label>Job title</label>
      </span>
    </div>

    <div class="col-12">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="department" />
        <label>Department</label>
      </span>
    </div>

    <div class="col-12">
      <span class="p-float-label">
        <input id="location" type="text" pInputText [(ngModel)]="location" />
        <label for="location">Location</label>
      </span>
    </div>

    <div class="col-12">
      <p-button label="Update profile" (onClick)="onConfirmUserDetails()"></p-button>
    </div>
  </div>

  <div *ngIf="!isMfaMandatory" class="col-1"></div>

  <div class="col-5 grid p-fluid">
    <h3 class="col-12 m-0">Change Password</h3>

    <div class="col-12">
      <span class="p-float-label">
        <input type="password" pInputText [(ngModel)]="oldPassword" />
        <label>Old Password</label>
      </span>
    </div>

    <div class="col-12">
      <span class="p-float-label">
        <input type="password" pInputText [(ngModel)]="newPassword" />
        <label>New Password</label>
      </span>
    </div>

    <div class="col-12">
      <span class="p-float-label">
        <input type="password" pInputText [(ngModel)]="confirmNewPassword" (keyup.enter)="onConfirmPassword()" />
        <label>Confirm New Password</label>
      </span>
    </div>

    <div class="col-12">
      <p-button label="Confirm password" [loading]="isChangingPassword" (onClick)="onConfirmPassword()"></p-button>
    </div>
    <app-mfa-control-panel [isMFAEnabled]="isMFAEnabled"></app-mfa-control-panel>
  </div>
</div>
