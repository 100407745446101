import { Component, OnInit, ViewChild } from '@angular/core';
import { FileUpload as PrimeNgFileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-logo-uploader',
  templateUrl: './logo-uploader.component.html',
  styleUrls: ['./logo-uploader.component.css']
})
export class LogoUploaderComponent implements OnInit {
  @ViewChild('uploaderInstance') uploaderInstance!: PrimeNgFileUpload;

  isUploading = false;

  constructor(private http: HttpClient, private readonly msgService: MessageService) {}

  ngOnInit(): void {}

  onUpload({ files }: FileUploadHandlerEvent) {
    if (files.length) {
      this.isUploading = true;
      const file = files[0];
      this.http.post('api/logo/get-upload-url', { fileName: file.name, fileSize: file.size, mimeType: file.type }).subscribe({
        next: (res) => {
          const { uploadUrl, logoPath } = res as { uploadUrl: string; logoPath: string };
          this.http.put(uploadUrl, file).subscribe({
            next: (res) => this.updateSystem(logoPath),
            error: (err) => this.onUploadError(err)
          });
        },
        error: (err) => this.onUploadError(err)
      });
    }
  }

  private updateSystem = async (logoPath: string) => {
    try {
      const obs = this.http.post('api/logo/save', { logoPath });
      await lastValueFrom(obs);
      this.msgService.add({ severity: 'success', summary: 'Success', detail: 'The logo has been successfully uploaded' });
      this.isUploading = false;
      location.reload();
    } catch (error) {
      this.onUploadError(error);
    }
  };

  private onUploadError = (err: any) => {
    this.isUploading = false;
    const msg = err?.error?.message || 'Failed to upload logo';
    this.msgService.add({ severity: 'error', summary: 'Error', detail: msg });
    this.uploaderInstance?.clear();
  };
}
