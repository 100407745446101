import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { showErrorWithSwal } from 'src/app/common/util/swal-mixins/swal-error';
import { IBillingData } from './api-interface';

@Injectable()
export class BillingService {
  constructor(private http: HttpClient) {}

  getPrimaryContactInfo(): Observable<IBillingData> {
    return this.http.get<IBillingData>('api/primaryBillingInformation/byMasterAccountId');
    // .pipe(
    //   catchError(this.handleError<IBillingData>('getPrimaryBillingInfo'))
    // );
  }

  getBillingContactInfo(): Observable<IBillingData> {
    return this.http.get<IBillingData>('api/billingInformation/byMasterAccountId');
    // .pipe(
    //   catchError(this.handleError<IBillingData>('getBillingContactInfo'))
    // );
  }

  savePrimaryContactInfo(data: IBillingData) {
    if (data.id) {
      return this.http
        .put<IBillingData>('api/primaryBillingInformation', data)
        .pipe(catchError(this.handleError<IBillingData>('savePrimaryContactInfo')));
    }
    return this.http
      .post<IBillingData>('api/primaryBillingInformation', data)
      .pipe(catchError(this.handleError<IBillingData>('savePrimaryContactInfo')));
  }

  saveBillingContactInfo(data: IBillingData) {
    if (data.id) {
      return this.http.put<IBillingData>('api/billingInformation', data).pipe(catchError(this.handleError<IBillingData>('saveBillingContactInfo')));
    }
    return this.http.post<IBillingData>('api/billingInformation', data).pipe(catchError(this.handleError<IBillingData>('saveBillingContactInfo')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      showErrorWithSwal(error.error ?? error);
      return of(result as T);
    };
  }
}
