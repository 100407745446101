import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BillingFormData } from '../../api-interface';

@Component({
  selector: 'app-billing-profile-form',
  templateUrl: './billing-profile-form.component.html',
  styleUrls: ['./billing-profile-form.component.css']
})
export class BillingProfileFormComponent implements OnInit {
  @Input() data!: BillingFormData;
  @Input() isSaving!: boolean;
  @Output() dataChange = new EventEmitter<BillingFormData>();
  @Output() userConfirm = new EventEmitter<void>();

  // internal models
  fullName!: string;
  jobTitle!: string;
  department!: string;
  basedIn!: string;
  email!: string;
  mobile!: string;

  constructor() {}

  get canSave() {
    return this.isSaving || !!this.fullName?.trim();
  }

  ngOnInit(): void {
    this.resetAll();
  }

  ngOnChanges(changes: any) {
    this.resetAll();
  }

  // Send the values up
  saveChanges(): void {
    const newValue: BillingFormData = {
      id: this.data.id,
      fullName: this.fullName?.trim(),
      jobTitle: this.jobTitle?.trim(),
      department: this.department?.trim(),
      basedIn: this.basedIn?.trim(),
      email: this.email?.trim(),
      mobile: this.mobile?.trim()
    };

    this.dataChange.emit(newValue);
    this.userConfirm.emit();
  }

  // Re-set all internal models with input data
  resetAll(): void {
    const data = this.data || {};

    this.fullName = data.fullName;
    this.jobTitle = data.jobTitle;
    this.department = data.department;
    this.basedIn = data.basedIn;
    this.email = data.email;
    this.mobile = data.mobile;
  }
}
