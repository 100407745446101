import Swal from 'sweetalert2';

/**
 * A sweetalert-based replacement for the native "alert" function.
 * Does not block.
 * @param message - message you want to show
 */
export async function swalAlert(message: string) {
  await Swal.fire(message);
}

/**
 * A sweetalert-based replacement for the native "confirm" function
 * @param message
 */
export async function swalConfirm(message: string): Promise<boolean> {
  const output = await Swal.fire({
    text: message,
    icon: 'warning',
    showCancelButton: true
  });
  return output.isConfirmed;
}
