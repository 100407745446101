import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BillingFormData } from './api-interface';
import { AuthProviderService } from '../../../-services-/auth-framework/auth-provider.service';
import { SwalToastSuccess } from '../../../common/util/swal-mixins/swal-toast-success';
import { BillingService } from './billing-page.service';

@Component({
  selector: 'app-billing-page',
  templateUrl: './billing-page.component.html',
  styleUrls: ['./billing-page.component.css']
})
export class BillingPageComponent implements OnInit {
  constructor(private ap: AuthProviderService, private router: Router, private billingService: BillingService) {}

  // forms
  primaryContactForm!: BillingFormData;
  billingContactForm!: BillingFormData;

  // internal states
  isFetchingPrimaryInfo = false;
  isFetchingBillingInfo = false;

  isSavingPrimaryInfo = false;
  isSavingBillingInfo = false;

  ngOnInit(): void {
    (async () => {
      const promises = [this.fetchPrimaryContactInfo(), this.fetchBillingContactInfo()];
      await Promise.all(promises);
    })();
  }

  fetchPrimaryContactInfo = () => {
    this.isFetchingPrimaryInfo = true;
    this.billingService.getPrimaryContactInfo().subscribe({
      next: (data) => {
        this.primaryContactForm = data || {};
        this.isFetchingPrimaryInfo = false;
      },
      error: (err) => {
        this.primaryContactForm = {} as BillingFormData;
        this.isFetchingPrimaryInfo = false;
      }
    });
  };

  fetchBillingContactInfo = () => {
    this.isFetchingBillingInfo = true;
    this.billingService.getBillingContactInfo().subscribe({
      next: (data) => {
        this.billingContactForm = data || {};
        this.isFetchingBillingInfo = false;
      },
      error: (err) => {
        this.billingContactForm = {} as BillingFormData;
        this.isFetchingBillingInfo = false;
      }
    });
  };

  onPrimaryFormConfirm(): void {
    this.isSavingPrimaryInfo = true;
    const data = this.primaryContactForm;
    this.billingService.savePrimaryContactInfo(data).subscribe((value) => {
      if (value) {
        SwalToastSuccess.fire({ title: 'Your changes have been saved.' });
      }
      this.isSavingPrimaryInfo = false;
      this.fetchPrimaryContactInfo();
    });
  }

  onSecondaryFormConfirm(): void {
    this.isSavingBillingInfo = true;
    const data = this.billingContactForm;
    this.billingService.saveBillingContactInfo(data).subscribe((value) => {
      if (value) {
        SwalToastSuccess.fire({ title: 'Your changes have been saved.' });
      }
      this.isSavingBillingInfo = false;
      this.fetchBillingContactInfo();
    });
  }
}
