import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-container',
  templateUrl: './user-admin-page-container.component.html',
  styleUrls: ['./user-admin-page-container.component.css']
})
export class UserAdminPageContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
