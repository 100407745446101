<p-dialog
  header='Give Feedback'
  [visible]='visible'
  (visibleChange)='visibleChange.emit($event)'
  [modal]='true'
  [maximizable]='true'
>

  <form [formGroup]='form'>

    <span class='p-float-label mt-3'>
      <input pInputText formControlName='title' />
      <label>Title</label>
    </span>
    <div
      class='p-error'
      *ngIf='!form.controls.title.valid && form.controls.title.dirty'
    >Please type in a title.
    </div>

    <div class='mt-3'>
      <span class='p-float-label'>
        <textarea
          pInputTextarea
          [formControl]='form.controls.content'
          [rows]='10'
        ></textarea>
        <label>Content</label>
      </span>
      <div
        class='p-error'
        *ngIf='!form.controls.content.valid && form.controls.content.dirty'
      >Please type in some content.
      </div>
    </div>

    <p-button
      label='Submit'
      [icon]='isLoading ? "pi pi-spin pi-spinner" : "pi pi-send"'
      [style]='{"margin-top": "2em"}'
      (click)='submit()'
      [disabled]='!form.valid || isLoading'
    ></p-button>

  </form>

</p-dialog>
