export enum EVENT_TYPE {
  DATA_SYNC = 'data-sync-update',
  COM_ENGINE = 'com-engine',
  APP_UPDATE = 'application-deployment'
}

export enum EVENT_STATUS {
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
  FAILED = 'failed',
  SCHEDULED = 'scheduled'
}

export interface EventData {
  message?: string;
  startDateTime?: Date | string;
  version?: string;
  status?: EVENT_STATUS;
  modified: string;
}
