<p-dialog
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
  header="Enabling MFA"
  [modal]="true"
>

  <app-enable-mfa-modal-loader *ngIf="visible"></app-enable-mfa-modal-loader>

</p-dialog>
