<p-button
  *ngIf="showMenu"
  icon="pi pi-cog"
  (onClick)="menu.toggle($event)"
  styleClass="p-button-rounded p-button-outlined mr-3"
  [rounded]="true"
  [outlined]="true"
></p-button>

<p-menu [popup]="true" #menu [model]="menuModel"></p-menu>
