<div class="m-3 p-3 grid p-card">
  <div class="grid p-fluid center-container">
    <div class="col-12">
      <h1 class="m-0">Create an E-Support Account</h1>
    </div>

    <div class="col-12">
      <form class="grid" [formGroup]="formGroup">
        <div class="col-12 p-fluid">
          <p-inputGroup>
            <input pInputText type="text" formControlName="userName" (input)="applyColorContrast($event)" placeholder="Email" />
            <p-inputGroupAddon>&#64;estm.com.au</p-inputGroupAddon>
          </p-inputGroup>
          <div class="error-message" *ngIf="formGroup.get('userName')!.invalid && formGroup.get('userName')!.touched">
            <div *ngIf="formGroup.get('userName')!.errors?.required">Email is required.</div>
            <div *ngIf="formGroup.get('userName')!.errors?.forbiddenCharacters">Email cannot contain forbidden characters(&#64; and |).</div>
          </div>
        </div>
        

        <!-- <div class="col-12 p-fluid">
          <span class="p-float-label">
            <input pInputText type="password" formControlName="password" (keyup.enter)="formGroup.valid && confirm()" />
            <label>Password</label>
          </span>
        </div> -->

        <div class="col-12 mt-3">
          <p-button [disabled]="!formGroup.valid || isLoading" (click)="confirm()">Confirm</p-button>
        </div>
      </form>
    </div>
  </div>
</div>
