import { Component } from '@angular/core';
import { AboutService } from './about.service';
import { EVENT_STATUS, EVENT_TYPE, EventData } from './about.types';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrl: './about.component.css',
  providers: [AboutService]
})
export class AboutComponent {
  constructor(private readonly aboutService: AboutService) {}

  isLoading = true;
  data: Record<string, EventData> = {};
  itemCount = 0;

  ngOnInit(): void {
    this.itemCount = this.items.length;
    this.fetchSystemInfo();
  }

  items = [
    {
      id: EVENT_TYPE.DATA_SYNC,
      label: 'Corporate Actions Database'
    },
    {
      id: EVENT_TYPE.COM_ENGINE,
      label: 'COM Engine'
    }
  ];

  fetchSystemInfo = () => {
    this.isLoading = true;
    const ids = this.items.map((item) => item.id);
    ids.forEach((infoKey) => {
      this.aboutService.getAboutInformation(infoKey).subscribe({
        next: (value: EventData[]) => {
          const data = value?.find((item) => item.status === EVENT_STATUS.COMPLETED);
          if (data) {
            this.data[infoKey] = { ...data };
            // console.log(data);
            // console.log(new Date(data.startDateTime as string));
          }
          this.stopLoading();
        },
        error: (err) => {
          console.error(err);
          this.stopLoading();
        }
      });
    });
  };

  stopLoading = () => {
    this.itemCount = this.itemCount - 1;
    if (this.itemCount <= 0) {
      this.isLoading = false;
    }
  };

  // process section message
  processMsg = (msg?: string) => msg?.replace('\n', '<br/>') || '';
}
