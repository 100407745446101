// This will hide user id from pages

import { TransformerResolveHook } from './hook';
import { BCDisplayTransformer } from '../transformer-resolver';
import { Path } from '../../path.model';
import { AliasTransformer, HideTransformer } from '../transformers';

export class HideUserIdHook extends TransformerResolveHook {

  run(path: Path): BCDisplayTransformer | null {

    const pathArray = path.value;

    // This if statement detects if the path is /profile/:id or /roles/:id
    if (/(profile|roles)/.test(pathArray[1])) {
      if (pathArray.length === 2) { // Is this the last route?
        return HideTransformer; // Hide the ID itself
      }
      return AliasTransformer('details'); // Not the last route; rename to "details" (last route will be hidden)
    }

    return null;

  }

}
