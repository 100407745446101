import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, mergeMap, switchMap, throwError } from 'rxjs';
import { apiConfig } from './-services-/auth-framework/api-connect/api-config';
import { servers } from './server.config';
import { AuthProviderService } from './-services-/auth-framework/auth-provider.service';
import { ACCESS_TOKEN_KEY } from './-services-/auth-framework/auth-provider.constants';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private authService: AuthProviderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const startedUnixTimestamp = Date.now();
    const newHeaders = this.processHeaders(req);
    const newUrl = this.processUrl(req.url);
    const newRequestObject = req.clone({
      headers: newHeaders,
      url: newUrl,
      withCredentials: true
    });
    return next.handle(newRequestObject).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 && this.isJwtExpiredError(error)) {
          return this.handleAccessDeniedError(newRequestObject, next);
        }
        return throwError(() => error);
      })
    );
    // return next.handle(newRequestObject).pipe(
    //   tap((event) => {
    //     if (event instanceof HttpResponse) {
    //       const elapsedMilliseconds = Date.now() - startedUnixTimestamp;
    //       console.log(
    //         `Request for ${req.urlWithParams} took ${elapsedMilliseconds} ms.`
    //       );
    //     }
    //   })
    // );
  }

  isJwtExpiredError(error: HttpErrorResponse): boolean {
    const accessDeniedValue = 'jwt expired';
    return error.error.message === accessDeniedValue && error.error.success === 'false';
  }

  handleAccessDeniedError(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.checkAuthStatus().pipe(
      mergeMap((data) => {
        console.log(data);
        const newHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
          // it is recommended to send the bearer token in authorization header with Bearer prefix
          // Authorization: `Bearer ${accessToken}`
          token: data.newAccessToken
        });
        const newRequestObject = req.clone({
          headers: newHeaders,
          withCredentials: true
        });
        // do something with data1
        return next.handle(newRequestObject);
      })
    );
  }
  /**
   * Get processed headers for the request.
   * This will add content-type and token to ESTM API requests only.
   * @param originalRequest the original request object
   * @private
   */
  private processHeaders(originalRequest: HttpRequest<unknown>) {
    if (!isESTMAfterLoginApiCall(originalRequest.url)) return originalRequest.headers;

    // const token = TokenPersistenceLayer.getToken(); // This can be null

    // let result = originalRequest.headers.set(
    //   'Content-Type',
    //   'application/json'
    // );
    // if (token) result = result.set('token', token);

    // refresh token call will not have headers as the authService may not be initialised
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) || '';
    return new HttpHeaders({
      'Content-Type': 'application/json',
      // it is recommended to send the bearer token in authorization header with Bearer prefix
      // Authorization: `Bearer ${accessToken}`
      token: accessToken
    });
  }

  /**
   * Run the URL through the pre-processor.
   * If the url starts with 'api' or 'pqte', prepend the url with baseURL.
   * Otherwise, return the URL as is.
   * @param url original request's url
   * @private
   */
  private processUrl(url: string) {
    if (isESTMApiCall(url)) {
      // TODO: currently using apiConfig hostname to keep single source of truth
      // Also since it is consumed by API-connector
      // In future, Remove this dependency and use interceptor as truth
      return `${apiConfig.hostname || servers.cloud}/${url}`;
      // const isLocal = localStorage.getItem('ESTM_HOST_SELECTOR_USE_LOCALHOST');
      // return `${isLocal === 'true' ? servers.localhost : servers.cloud}/${url}`;
    } else {
      return url;
    }
  }
}

function isESTMApiCall(url: string) {
  return /^(api|pqte|mfa|auth|refresh)/gi.test(url);
}

function isESTMAfterLoginApiCall(url: string) {
  return /^(api\/|pqte\/|mfa\/|auth\/|refresh)/gi.test(url);
}
