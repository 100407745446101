<p-button
    icon="no-thanks.invalid"
    styleClass="p-button-lg p-button-rounded"
    (click)="menu.toggle($event)"
    [pTooltip]="generateTooltipHTML()"
    tooltipPosition="bottom"
    appendTo="body"
    [rounded]="true"
    tooltipStyleClass="profile-button-tooltip"
    [escape]="false"
>
    <span class="user-initial">{{ computeInitials() }}</span>
</p-button>

<p-menu [popup]="true" [model]="menuItems" #menu></p-menu>
<app-feedback-modal [(visible)]="modalOpen"></app-feedback-modal>
