<h3 class="col-12 m-0">Multi-factor Authentication</h3>

<div class="col-12">
  MFA Status: <strong>{{ renderMFAStatus() }}</strong>
</div>

<div class="col-12">
  <p-button *ngIf="renderMFAStatus() === 'Disabled'" (click)="enableMFA()">Enable</p-button>
  <p-button *ngIf="renderMFAStatus() === 'Enabled'" (click)="disableMFA()">Disable</p-button>
</div>

<app-enable-mfa-modal [(visible)]="modalOpen"></app-enable-mfa-modal>
