import { setUpstreamHost } from '../../../-services-/auth-framework/api-connect/api-config';
import { servers } from '../../../server.config';

class HostSelector {
  isUsingLocalhost: boolean;

  private static servers = servers;

  private static localStorageKey = 'ESTM_HOST_SELECTOR_USE_LOCALHOST';

  constructor() {
    this.isUsingLocalhost = this.getSavedSetting();
    this.onChange();
  }

  switchToCloudServer() {
    this.isUsingLocalhost = false;
    this.onChange();
  }

  switchToLocalhost() {
    this.isUsingLocalhost = true;
    this.onChange();
  }

  private onChange() {
    this.saveSetting();
    if (this.isUsingLocalhost) {
      setUpstreamHost(HostSelector.servers.localhost);
    } else {
      setUpstreamHost(HostSelector.servers.cloud);
    }
  }

  /**
   * Reads whether the saved setting (in localStorage) says to use localhost or not
   * If saved setting is not present, defaults to false.
   * @private
   */
  private getSavedSetting(): boolean {
    const readout = localStorage.getItem(HostSelector.localStorageKey);
    return readout == 'true';
  }

  private saveSetting() {
    if (this.isUsingLocalhost) {
      localStorage.setItem(HostSelector.localStorageKey, 'true');
    } else {
      localStorage.setItem(HostSelector.localStorageKey, 'false');
    }
  }
}

export const HostSelectorInstance = new HostSelector();
