import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Path } from './path.model';
import { TransformerResolver } from './display-transformers/transformer-resolver';
import { Subscription } from 'rxjs';
import { truthyOnly } from '../../../utilities/truthy-only';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  constructor(private router: Router) {}

  items: MenuItem[] = [];

  homeItem: MenuItem = {
    icon: 'pi pi-home',
    routerLink: '/'
  };

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscriptions.push(
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
        this.refreshMenuItems();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Re-generates menu items based on current route
   * @private
   */
  private refreshMenuItems(): void {
    const path = this.getPath();
    const subPaths = path.getAllSubPaths();
    this.items = subPaths.map((p) => BreadcrumbComponent.getMenuItemForSubPath(p)).filter(truthyOnly); // Ignore all nulls
  }

  /**
   * Calculates a menu item based on 1 path
   * @param subPath - an array of strings. Should be a subset of this.getUrlDirectoryStrings()
   * @private
   */
  private static getMenuItemForSubPath(subPath: Path): MenuItem | null {
    const transformer = TransformerResolver.resolve(subPath);
    return transformer(subPath); // Calling the transformer returns a menu item
  }

  private getPath(): Path {
    return Path.fromURL(this.router.url);
  }
}
