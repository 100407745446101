<p-fileUpload
  (uploadHandler)="onUpload($event)"
  [customUpload]="true"
  [maxFileSize]="10 * 1024 * 1024"
  mode="basic"
  accept="image/*"
  [auto]="true"
  [chooseIcon]="isUploading ? 'pi pi-spin pi-spinner' : 'pi pi-upload'"
  [chooseLabel]="isUploading ? 'Uploading New Logo' : 'Upload New Logo'"
  [disabled]="isUploading"
  #uploaderInstance
>
</p-fileUpload>
