import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-pages-container',
  templateUrl: './terms-pages-container.component.html'
})
export class TermsPagesContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
