import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequiresRoleToSeeDirective } from './restriction-directives/requires-role-to-see.directive';
import { RequiresRoleToWriteDirective } from './restriction-directives/requires-role-to-write.directive';

@NgModule({
  declarations: [RequiresRoleToSeeDirective, RequiresRoleToWriteDirective],
  exports: [RequiresRoleToSeeDirective, RequiresRoleToWriteDirective],
  imports: [CommonModule]
})
export class AuthServiceModule {}
