// A collection of all transformer functions.

import { Path } from '../path.model';
import { BCDisplayTransformer } from './transformer-resolver';

export const NullTransformer = (path: Path) => {
  return {
    label: path.lastRouteName?.replace(/-/g, ' '),
    routerLink: path.fullURL
  };
};

export const HideTransformer = () => {
  return null;
};

/**
 * A higher-level function to return a transformer that changes the display name
 * of the specific item.
 * @param alias - the new name.
 */
export function AliasTransformer(alias: string): BCDisplayTransformer {
  return (path: Path) => {
    const result = NullTransformer(path);
    result.label = alias;
    return result;
  };
}
