import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import { Role } from '../api-constants/roles/role';
import { AuthProviderService } from '../auth-provider.service';

@Directive({
  selector: '[appRequiresRoleToSee]'
})
export class RequiresRoleToSeeDirective implements OnInit {

  constructor(private apService: AuthProviderService) { }

  @HostBinding('style.display') hostStyleDisplay!: string;
  @Input('appRequiresRoleToSee') authorisedGroups!: Role[];

  ngOnInit() {
    if (!this.checkQualification()) {
      this.hideHost();
    }
  }

  checkQualification(): boolean {
    return this.authorisedGroups.some(role => this.apService.verifyUserRole(role));
  }

  showHost() {
    this.hostStyleDisplay = '';
  }

  hideHost() {
    this.hostStyleDisplay = 'none';
  }

}
