<blockable-div #pagecontent>
  <div class="flex gap-3 mt-3 flex-wrap card-container">
    <ng-container *ngFor="let item of items">
      <p-card class="card lg:col-4 sm:col-6 md:col-4" *ngIf="data[item.id]">
        <ng-template pTemplate="header">
          <div class="card-header-container">
            <h2 class="m-0 card-header">{{ item.label }}</h2>
            <h4 class="m-0 card-version">{{ data[item.id].version }}</h4>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="container p-fluid">
            <div class="col-12" *ngIf="data[item.id].message">
              <app-description-htmlrenderer [html]="processMsg(data[item.id].message)"></app-description-htmlrenderer>
            </div>
            <div class="col-12">
              <strong>Information as at:</strong>
              <br />
              <span *ngIf="data[item.id].startDateTime; else na_block">
                {{ data[item.id].modified | date : 'mediumDate' }}
              </span>
              <ng-template #na_block> N/A </ng-template>
            </div>
          </div>
        </ng-template>
      </p-card>
    </ng-container>
  </div>
</blockable-div>
<p-blockUI [target]="pagecontent" [blocked]="isLoading">
  <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="4" animationDuration=".5s"></p-progressSpinner>
</p-blockUI>
