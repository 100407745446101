export class ErrorEvent {

  private silenced = false;

  constructor(public readonly originalError: unknown) {
  }

  getOriginalError() {
    return this.originalError;
  }

  isSilenced() {
    return this.silenced;
  }

  markAsSilenced(): void {
    this.silenced = true;
  }

}
