<p-sidebar [(visible)]="sidebarVisible" position="right" [dismissible]="true" [blockScroll]="true" (onHide)="closeSideBar()" styleClass="w-30rem">
  <ng-template pTemplate="header">
    <h2 [style]="{ 'margin-bottom': 0 }">Notifications</h2>
  </ng-template>

  <ng-template pTemplate="content">
    <p-dataView #dv [value]="notifications" emptyMessage="No notifications received">
      <ng-template let-notifications pTemplate="list">
        <div class="grid grid-nogutter">
          <div class="col-12" *ngFor="let notification of notifications; let first = first">
            <div class="flex flex-column xl:flex-row gap-2" [ngClass]="{ 'border-top-1 surface-border': !first }">
              <div class="flex flex-column sm:flex-row justify-content-between align-items-center flex-1">
                <div class="flex flex-column align-items-center sm:align-items-start">
                  <div class="text-900 notification-text" [ngSwitch]="notification.type">
                    <ng-container *ngSwitchCase="'REPORT'">
                      <div>
                        {{ notification.content.subCategory | uppercase }} report generation for portfolio '{{ notification.content.portfolioName }}'
                        ({{ notification.content.portfolioCode }}) :
                        <strong>{{ notification.content.status | uppercase }}</strong>
                        <div *ngIf="isFailedAndHasErrorMsg(notification)">
                          <span style="color: red"> {{ notification.content.message }}</span>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'PORTFOLIO_PROCESSING'">
                      Activity processing for portfolio '{{ notification.content.portfolioName }}' ({{ notification.content.portfolioCode }}) :
                      <strong> {{ notification.content.status || 'NOT STARTED' | uppercase }}</strong>
                      <div *ngIf="notification.content.message">
                        <span style="color: red"> {{ notification.content.message }}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'RELEASE_NOTES'">
                      Release notes generation for {{ notification.content.startDate | appDate }} - {{ notification.content.endDate | appDate }} :
                      <strong> {{ notification.content.status || 'NOT STARTED' | uppercase }}</strong>
                    </ng-container>

                    <ng-container *ngSwitchCase="'CONTRACT_NOTES'"
                      >{{ notification.content.summary }} : {{ notification.content.detail }}</ng-container
                    >
                    <ng-container *ngSwitchCase="'IMPORT_FILE'">
                      Status of {{ notification.content.fileName }} import :
                      <strong>{{ notification.content.status || 'SCHEDULED' | uppercase }}</strong>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      New notification of type
                      <b>{{ notification.type }}</b> received with status:
                      <strong>{{ notification.content.status || 'NOT STARTED' | uppercase }}</strong>
                      <div *ngIf="notification.content.message">
                        <span style="color: red"> {{ notification.content.message }}</span>
                      </div>
                    </ng-container>

                    <div
                      title="This link is valid only for an hour"
                      *ngIf="notification.content.status === 'Done' || notification.content.downloadLink?.length"
                    >
                      Click
                      <a target="_blank" [href]="notification.content.downloadLink">here</a>
                      to download!
                    </div>
                  </div>
                </div>
                <div class="flex sm:flex-column align-items-center sm:align-items-end">
                  <p-button
                    icon="pi pi-times"
                    styleClass="p-button-rounded p-button-secondary p-button-text"
                    class="md:align-self-end"
                    (click)="dismissNotification(notification.notificationId)"
                    [rounded]="true"
                    [text]="true"
                  ></p-button>
                </div>
              </div>
            </div>
            <div class="notification-date">
              {{ notification.date | date : 'd/M/yy, h:mm a' }}
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button
      [disabled]="!notifications.length"
      label="Dismiss All"
      styleClass="p-button-outlined p-button-secondary"
      (click)="dismissAllNotifications()"
    ></p-button>
  </ng-template>
</p-sidebar>
