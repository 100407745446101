import {Component, OnInit} from '@angular/core';
import {MfaService} from "../../../mfa.service";
import { extractErrorText } from '../../../../../../../utilities/extract-error-text';

@Component({
  selector: 'app-mfa-confirm-inputgroup',
  templateUrl: './mfa-confirm-inputgroup.component.html',
  styleUrls: ['./mfa-confirm-inputgroup.component.css']
})
export class MfaConfirmInputgroupComponent implements OnInit {

  value = '';
  isLoading = false;
  error = '';

  constructor(private mfaService: MfaService) { }

  ngOnInit(): void {
  }

  async confirm() {
    await this.runWithStateTracking(async () => {
      await this.mfaService.confirmEnableMFA(this.value);
    });
  }

  private async runWithStateTracking(operation: () => Promise<any>) {
    try {
      this.isLoading = true;
      await operation();
    } catch (e) {
      this.error = extractErrorText(e);
    } finally {
      this.isLoading = false;
    }
  }
}
