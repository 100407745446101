<div class="p-card grid m-3 p-3">
  <div class="col-12 flex">
    <div class="flex-1">
      <h1 class="m-0">Billing</h1>
      <div>Manage your billing and contact information</div>
    </div>
    <app-logo-uploader></app-logo-uploader>
  </div>

  <div class="col-6">
    <h3>Primary Contact</h3>
    <app-notice-card *ngIf="isFetchingPrimaryInfo" icon="pi pi-spin pi-spinner"></app-notice-card>
    <app-billing-profile-form
      *ngIf="!isFetchingPrimaryInfo"
      [(data)]="primaryContactForm"
      (userConfirm)="onPrimaryFormConfirm()"
      [isSaving]="isSavingPrimaryInfo"
    ></app-billing-profile-form>
  </div>

  <div class="col-6">
    <h3>Billing Contact</h3>
    <app-notice-card *ngIf="isFetchingBillingInfo" icon="pi pi-spin pi-spinner"></app-notice-card>
    <app-billing-profile-form
      *ngIf="!isFetchingBillingInfo"
      [(data)]="billingContactForm"
      (userConfirm)="onSecondaryFormConfirm()"
      [isSaving]="isSavingBillingInfo"
    ></app-billing-profile-form>
  </div>
</div>
